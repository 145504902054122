import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { track } from '../../../helpers/analyticsHelpers';
import { AIModel, AnalyticsEvent } from '../../../types';

type PlaygroundEventType = 'message';

type PlaygroundEvent = {
  id: string;
  prompt: string;
  response: string;
  type: PlaygroundEventType;
  created_ts: number;
};

type PlaygroundHistory = PlaygroundEvent[];

export interface PlaygroundState {
  sessionPlaygroundAnswerCount: number;
  history: PlaygroundHistory;
  model: AIModel;
}

const finalHistory = () => {
  const history = localStorage.getItem('playgroundHistory')
    ? (JSON.parse(
        localStorage.getItem('playgroundHistory') || '[]'
      ) as PlaygroundHistory)
    : [];
  return history;
};

const initialState: PlaygroundState = {
  sessionPlaygroundAnswerCount: 0,
  history: finalHistory(),
  model: AIModel['gpt-4o-mini'],
};

export const playgroundSlice = createSlice({
  name: 'playground',
  initialState,
  reducers: {
    promptSubmitted: (
      state,
      action: PayloadAction<{ id: string; prompt: string; response: string }>
    ) => {
      const event = {
        id: action.payload.id,
        type: 'message' as const,
        created_ts: Date.now(),
        prompt: action.payload.prompt,
        response: action.payload.response,
      };
      state.history.push(event);
      track(AnalyticsEvent.PromptSubmitted, event);
      localStorage.setItem('playgroundHistory', JSON.stringify(state.history));
    },
    clearHistory: (state) => {
      state.history = [];
      localStorage.setItem('playgroundHistory', JSON.stringify(state.history));
    },
    streamFinished: (state) => {
      state.sessionPlaygroundAnswerCount =
        state.sessionPlaygroundAnswerCount + 1;
    },
    modelUpdated: (state, action: PayloadAction<AIModel>) => {
      state.model = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { promptSubmitted, clearHistory, streamFinished, modelUpdated } =
  playgroundSlice.actions;

export default playgroundSlice.reducer;
