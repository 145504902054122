import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  getLocalStorageBotCollapseStates,
  getLocalStorageCollapseStates,
  getLocalStorageMode,
  setLocalStorageBotCollapseStates,
  setLocalStorageCollapseStates,
  setLocalStorageMode,
} from '../../../helpers/localStorageHelpers';
import {
  BotCollapseStates,
  CollapseStates,
  CollapsibleSection,
} from '../../../types';

export interface UiState {
  botCollapseStates: BotCollapseStates;
  copyToPromptText?: string;
  collapseStates: CollapseStates;
  deleteBotId?: string;
  focusEditBotSystemPrompt: boolean;
  isMoreMenuVisible: boolean;
  isShowingKeyboard: boolean;
  mode?: 'light' | 'dark';
  scrollToEndOfChat: boolean;
}

const initialState: UiState = {
  botCollapseStates: getLocalStorageBotCollapseStates(),
  collapseStates: getLocalStorageCollapseStates(),
  focusEditBotSystemPrompt: false,
  isMoreMenuVisible: false,
  isShowingKeyboard: false,
  mode: getLocalStorageMode(),
  scrollToEndOfChat: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    copyToPromptTextUpdated: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.copyToPromptText = action.payload;
    },
    deleteBotIdUpdated: (state, action: PayloadAction<string | undefined>) => {
      state.deleteBotId = action.payload;
    },
    focusEditBotSystemPromptUpdated: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.focusEditBotSystemPrompt = action.payload;
    },
    isMoreMenuVisibleUpdated: (state, action: PayloadAction<boolean>) => {
      state.isMoreMenuVisible = action.payload;
    },
    isShowingKeyboardUpdated: (state, action: PayloadAction<boolean>) => {
      state.isShowingKeyboard = action.payload;
    },
    scrollToEndOfChatUpdated: (state, action: PayloadAction<boolean>) => {
      state.scrollToEndOfChat = action.payload;
    },
    modeUpdated: (state, action: PayloadAction<'light' | 'dark'>) => {
      state.mode = action.payload;
      setLocalStorageMode(action.payload);
    },
    sectionCollapsed: (
      state,
      action: PayloadAction<{ section: CollapsibleSection }>
    ) => {
      state.collapseStates = {
        ...state.collapseStates,
        [action.payload.section]: true,
      };
      setLocalStorageCollapseStates(state.collapseStates);
    },
    sectionExpanded: (
      state,
      action: PayloadAction<{ section: CollapsibleSection }>
    ) => {
      state.collapseStates = {
        ...state.collapseStates,
        [action.payload.section]: false,
      };
      setLocalStorageCollapseStates(state.collapseStates);
    },
    botCollapsed: (state, action: PayloadAction<string>) => {
      state.botCollapseStates = {
        ...state.botCollapseStates,
        [action.payload]: true,
      };
      setLocalStorageBotCollapseStates(state.botCollapseStates);
    },
    botExpanded: (state, action: PayloadAction<string>) => {
      state.botCollapseStates = {
        ...state.botCollapseStates,
        [action.payload]: false,
      };
      setLocalStorageBotCollapseStates(state.botCollapseStates);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  copyToPromptTextUpdated,
  deleteBotIdUpdated,
  focusEditBotSystemPromptUpdated,
  isMoreMenuVisibleUpdated,
  isShowingKeyboardUpdated,
  scrollToEndOfChatUpdated,
  modeUpdated,
  sectionCollapsed,
  sectionExpanded,
  botCollapsed,
  botExpanded,
} = uiSlice.actions;

export default uiSlice.reducer;
