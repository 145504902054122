import { Toast } from '@capacitor/toast';

export const showShortToast = async (text: string) => {
  await Toast.show({
    text,
    duration: 'short',
  });
};

export const showLongToast = async (text: string) => {
  await Toast.show({
    text,
    duration: 'long',
  });
};
