import mixpanel from 'mixpanel-browser';
import { AnalyticsEvent, CategoryClickEvent, ClickEvent } from '../types';

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  });
} else {
  throw new Error('REACT_APP_MIXPANEL_TOKEN is not set');
}

export const track = (
  event: AnalyticsEvent | ClickEvent | CategoryClickEvent,
  properties?: Record<string, any>
) => {
  mixpanel.track(event, {
    properties,
  });
};
