import { Capacitor } from '@capacitor/core';
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useAppDispatch } from '../../hooks';
import {
  botCreated,
  currentBotIdUpdated,
} from '../../redux/domain/bot/botSlice';
import { Bot } from '../../types';
import BotAvatar from '../BotAvatar';
import InfoDrawerSystemPrompt from '../navigation/InfoDrawerSystemPrompt';
import WizardSelect from '../WizardSelect';

const AddBotPage = ({
  onCancel,
  onSubmit,
}: {
  onCancel?: () => void;
  onSubmit?: () => void;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { pathname } = location;
  const bot: Bot | undefined = location.state?.bot;
  const nameOverride: string | undefined = location.state?.nameOverride;
  const promptOverride: string | undefined = location.state?.promptOverride;

  const onClose = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const defaultName = nameOverride ?? bot?.name ?? '';
  const defaultSystemPrompt = promptOverride ?? bot?.currentSystemPrompt ?? '';

  const [name, setName] = useState(defaultName);
  const [systemPrompt, setSystemPrompt] = useState(defaultSystemPrompt);
  const [showBorderStyles, setShowBorderStyles] = useState<
    'name' | 'prompt' | undefined
  >(undefined);

  const hasName = name.trim().length > 0;
  const hasSystemPrompt = systemPrompt.trim().length > 0;

  const isValid = hasName && hasSystemPrompt;

  const defaultBotProps = useMemo(
    () => ({
      chatHistory: {},
      id: uuid(),
      streamResponse: '',
    }),
    []
  );

  const defaultBot = useMemo(
    () => ({
      ...defaultBotProps,
      name,
      currentSystemPrompt: systemPrompt,
      systemPromptHistory: { [Date.now()]: systemPrompt },
      created_ts: Date.now(),
      updated_ts: Date.now(),
    }),
    [defaultBotProps, name, systemPrompt]
  );

  const onSave = useCallback(() => {
    dispatch(botCreated(defaultBot));
    if (pathname === '/add-bot' || pathname === '/copy-bot') {
      dispatch(currentBotIdUpdated(defaultBot.id));
      navigate(`/bots/${defaultBot.id}`);
    }
    onSubmit?.();
  }, [defaultBot, dispatch, navigate, onSubmit, pathname]);

  const nameBorderStyles =
    showBorderStyles === 'name'
      ? {
          border: '1px solid',
          borderColor: theme.palette.primary.main,
        }
      : {};

  const promptBorderStyles =
    showBorderStyles === 'prompt'
      ? {
          border: '1.5px solid',
          borderColor: theme.palette.primary.main,
        }
      : {};

  const showAvatar = Capacitor.isNativePlatform() && defaultBot;

  const nameWithMaybeAvatar = showAvatar ? (
    <Box
      sx={{ display: 'flex', alignItems: 'center', gap: 0.5, width: '100%' }}
    >
      {Capacitor.isNativePlatform() && defaultBot ? (
        <BotAvatar
          bot={defaultBot}
          sx={{
            width: 80,
            height: 80,
          }}
        />
      ) : null}
      <TextField
        onBlur={() => {
          setShowBorderStyles(undefined);
        }}
        onFocus={(e) => {
          setShowBorderStyles('name');
        }}
        InputProps={{
          sx: {
            borderRadius: '0.5rem',
            pl: 1,
            fontSize: '18px',
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            ...nameBorderStyles,
          },
        }}
        fullWidth
        onChange={(e) => setName(e.target.value)}
        value={name}
        placeholder="My Bot"
      />
    </Box>
  ) : (
    <Stack>
      <Typography sx={{ px: 1, fontWeight: 600 }}>Name</Typography>
      <TextField
        InputProps={{
          sx: {
            borderRadius: '0.5rem',
            pl: 1,
            fontSize: '18px',
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            ...nameBorderStyles,
          },
        }}
        onChange={(e) => setName(e.target.value)}
        value={name}
        placeholder="My Bot"
      />
    </Stack>
  );

  return (
    <Container>
      <Box sx={{ py: 1 }}>
        <Stack spacing={2}>
          <Divider>Start with a template</Divider>
          <WizardSelect />
          <Divider>or build your own darn bot</Divider>
          {nameWithMaybeAvatar}
          <Stack>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Typography sx={{ fontWeight: 600 }}>System Prompt</Typography>
              <InfoDrawerSystemPrompt />
            </Box>
            <TextareaAutosize
              onBlur={() => {
                setShowBorderStyles(undefined);
              }}
              onFocus={(e) => {
                setShowBorderStyles('prompt');
              }}
              placeholder="Write me an essay about "
              style={{
                marginTop: '4px',
                fontSize: '18px',
                borderRadius: '0.5rem',
                padding: '1rem',
                outline: 'none',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                backgroundColor: theme.palette.background.paper,
                ...promptBorderStyles,
              }}
              maxRows={9}
              minRows={3}
              onChange={(e) => setSystemPrompt(e.target.value)}
              value={systemPrompt}
            />
          </Stack>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, py: 1 }}>
            <Button
              sx={{ borderRadius: '2rem' }}
              fullWidth
              onClick={onCancel ?? onClose}
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
            <Button
              sx={{ borderRadius: '2rem' }}
              fullWidth
              variant="contained"
              disabled={!isValid}
              onClick={onSave}
              size="large"
            >
              {bot ? 'Copy Bot' : 'Add Bot'}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default AddBotPage;
