import { AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { BatteryInfo, DeviceInfo } from '@capacitor/device';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getJSON } from '../../../helpers/staticHelpers';
import { Prompt } from '../../../types';
import { store } from '../../store';

// JSON data
interface Lists {
  subjects: string[];
  languages: string[];
  programmingLanguages: string[];
}

export interface AppState {
  sessionStreamCount: number; // used for prompting for app ratings
  prompts: Prompt[];
  lists: Lists;
  mobile: {
    isMobile: boolean;
    app?: AppInfo;
    device?: DeviceInfo;
    battery?: BatteryInfo;
    promptForRating: boolean;
  };
}

const initialState: AppState = {
  sessionStreamCount: 0,
  prompts: [],
  lists: {
    languages: [],
    programmingLanguages: [],
    subjects: [],
  },
  mobile: {
    isMobile: Capacitor.isNativePlatform(),
    promptForRating: false,
  },
};

const populateJSONData = async () => {
  const { prompts } = await getJSON('/prompts.json');
  store.dispatch(promptsUpdated(prompts as Prompt[]));
  const [languages, programmingLanguages, subjects] = await Promise.all([
    getJSON('/lists/languages.json'),
    getJSON('/lists/programming.json'),
    getJSON('/lists/subjects.json'),
  ]);
  store.dispatch(listsUpdated({ languages, programmingLanguages, subjects }));
};
populateJSONData();

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    promptsUpdated: (state, action: PayloadAction<Prompt[]>) => {
      state.prompts = action.payload;
    },
    listsUpdated: (state, action: PayloadAction<Lists>) => {
      state.lists = action.payload;
    },
    appInfoUpdated: (state, action: PayloadAction<AppInfo>) => {
      state.mobile.app = action.payload;
    },
    mobileDeviceInfoUpdated: (state, action: PayloadAction<DeviceInfo>) => {
      state.mobile.device = action.payload;
    },
    mobileBatteryInfoUpdated: (state, action: PayloadAction<BatteryInfo>) => {
      state.mobile.battery = action.payload;
    },
    streamFinished: (state) => {
      state.sessionStreamCount = state.sessionStreamCount + 1;
    },
  },
});

export const {
  appInfoUpdated,
  mobileDeviceInfoUpdated,
  mobileBatteryInfoUpdated,
  listsUpdated,
  promptsUpdated,
  streamFinished,
} = appSlice.actions;

export default appSlice.reducer;
