import { Capacitor } from '@capacitor/core';
import { DeviceInfo } from '@capacitor/device';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import ResetScrollTop from './components/behavior/ResetScrollTop';
import MobileFooter from './components/navigation/MobileFooter';
import WebFooter from './components/navigation/WebFooter';
import AboutPage from './components/pages/AboutPage';
import AddBotPage from './components/pages/AddBotPage';
import AppInfoPage from './components/pages/AppInfoPage';
import BotHistoryPage from './components/pages/BotHistoryPage';
import BotPage from './components/pages/BotPage';
import DeleteBotPage from './components/pages/DeleteBotPage';
import EditBotPage from './components/pages/EditBotPage';
import HomePage from './components/pages/HomePage';
import LanguageTutorPage from './components/pages/LanguageTutorPage';
import MobileDebugInfoPage from './components/pages/MobileDebugInfoPage';
import NotFoundPage from './components/pages/NotFoundPage';
import PlaygroundHistoryPage from './components/pages/PlaygroundHistoryPage';
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage';
import ProgrammingWizardPage from './components/pages/ProgrammingWizardPage';
import SubjectsPage from './components/pages/SubjectsPage';
import SupportPage from './components/pages/SupportPage';
import Templates from './components/pages/TemplatesPage';
import TermsOfServicePage from './components/pages/TermsOfServicePage';
import TitleBar from './components/TitleBar';
import { useAppSelector } from './hooks';
import { IOS_RETURN_URL_AFTER_SIGNOUT } from './mobile/constants';
import { IPHONE_SE_MODEL } from './redux/domain/app/appConstants';

const getMarginBottomForMobile = (pathname: string) => {
  const isBotPage = pathname.startsWith('/bots/');
  const isHomePage = pathname === '/' || pathname === '';
  const isLegalPage = pathname === '/privacy' || pathname === '/terms';

  if (isBotPage) {
    return '10px';
  }
  if (isHomePage) {
    return '45px';
  }
  if (isLegalPage) {
    return '45px';
  }

  return '20px';
};

const getMainAppStyles = (pathname: string, deviceInfo?: DeviceInfo) => {
  const isHomePage = pathname === '/' || pathname === '';

  const margins = {
    mr: 'env(safe-area-inset-right)',
    ml: 'env(safe-area-inset-left)',
  };
  const capacitorAdjustment = Capacitor.isNativePlatform()
    ? deviceInfo?.model === IPHONE_SE_MODEL
      ? '24px'
      : isHomePage
      ? '40px'
      : '48px'
    : '0px';

  const padding = {
    pt: `calc(env(safe-area-inset-top) + ${capacitorAdjustment} + 65px)`,
    pb: `calc(env(safe-area-inset-bottom) + ${
      Capacitor.isNativePlatform()
        ? `calc(48px + ${getMarginBottomForMobile(pathname)})`
        : '70px'
    })`,
    px: 0.5,
  };

  const styles = {
    ...padding,
    ...margins,
    minHeight: '100vh',
  };

  return styles;
};

const Layout = () => {
  const { pathname } = useLocation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isMobileView = Capacitor.isNativePlatform() || isSmallScreen;
  const deviceInfo = useAppSelector((s) => s.app.mobile.device);

  const mobileContent = (
    <Box sx={getMainAppStyles(pathname, deviceInfo)}>
      <TitleBar />
      <Outlet />
      <MobileFooter />
      <ResetScrollTop />
    </Box>
  );

  const webContent = (
    <>
      <div className="content">
        <TitleBar />
        {/* 60px for the header height, 10px for additional padding  */}
        <Box
          sx={{
            pt: '70px',
            pb: '70px',
            minHeight: '100vh',
            px: { xs: 1, sm: 2 },
          }}
        >
          <Outlet />
        </Box>
      </div>
      <WebFooter />
      <ResetScrollTop />
    </>
  );

  return isMobileView ? mobileContent : webContent;
};

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path={IOS_RETURN_URL_AFTER_SIGNOUT} element={<HomePage />} />
        <Route path="/bots/:botId" element={<BotPage />} />
        <Route path="/add-bot" element={<AddBotPage />} />
        <Route path="/copy-bot" element={<AddBotPage />} />
        <Route path="/playground-history" element={<PlaygroundHistoryPage />} />
        <Route path="/bot-history/:botId" element={<BotHistoryPage />} />
        <Route path="/edit-bot" element={<EditBotPage />} />
        <Route path="/delete-bot" element={<DeleteBotPage />} />
        <Route path="/programming" element={<ProgrammingWizardPage />} />
        <Route path="/subjects" element={<SubjectsPage />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/language-tutor" element={<LanguageTutorPage />} />
        <Route path="/bots" element={<HomePage />} />
        <Route path="/prompts" element={<Templates />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfServicePage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/mobile-debug-info" element={<MobileDebugInfoPage />} />
        <Route path="/app-info" element={<AppInfoPage />} />
        <Route
          path="*"
          element={
            Capacitor.isNativePlatform() ? <HomePage /> : <NotFoundPage />
          }
        />
      </Route>
    </Routes>
  );
}
