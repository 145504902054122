import { Box, Stack, Typography } from '@mui/material';
import { TermsOfServiceLastUpdated } from '../../redux/domain/app/appConstants';
import TermsOfServiceContent from '../TermsOfServiceContent';

export default function TermsOfServicePage() {
  return (
    <Box sx={{ p: { xs: 1, md: 2 } }}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="h4">Terms of Service</Typography>
          <Typography color="text.secondary" variant="h6">
            Last updated {TermsOfServiceLastUpdated}
          </Typography>
        </Box>
        <TermsOfServiceContent />
      </Stack>
    </Box>
  );
}
