import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

export const openUrl = async (url: string) => {
  if (Capacitor.isNativePlatform()) {
    await Browser.open({
      url,
      presentationStyle: 'popover',
      width: 600, // iPad only
      height: 600, // iPad only
    });
  } else {
    window.open(url, '_blank');
  }
};
