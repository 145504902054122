import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ResetScrollTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      window.scrollTo({ left: 0, top: 0 });
    }
  }, [pathname]);

  return null;
};

export default ResetScrollTop;
