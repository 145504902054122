import { Capacitor } from '@capacitor/core';
import { Check, ContentCopy, IosShare, Share } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import gfm from 'remark-gfm';
import { showBotMessageActions } from '../mobile/actionSheet';
import { shareText } from '../mobile/share';

export default function BotMessage({ content }: { content: string }) {
  const theme = useTheme();
  const pressTimer = useRef<NodeJS.Timeout>();

  const handlePointerDown = useCallback(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    pressTimer.current = setTimeout(async () => {
      await showBotMessageActions(content);
    }, 300);
  }, [content]);

  const handlePointerUp = () => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    clearTimeout(pressTimer.current);
  };

  const onCodeShare = useCallback(async (text: string) => {
    await shareText(text);
  }, []);

  const [copied, setCopied] = useState(false);

  return (
    <Box
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerUp}
      sx={{
        userSelect: Capacitor.isNativePlatform() ? 'none' : undefined,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EB' : '#444444',
        px: 2,
        width: 'fit-content',
        borderRadius: 5,
        mr: 2,
        my: 0.5,
      }}
    >
      <Stack>
        <Box sx={{ maxWidth: '85vw', mx: 'auto', overflow: 'hidden' }}>
          <ReactMarkdown
            children={content}
            remarkPlugins={[gfm]}
            components={{
              code({ node, inline, className, children, index, ...props }) {
                const match = /language-(\w+)/.exec(className || '');

                const maybeLanguage = match ? match[1] : undefined;
                const onShare = (e: any) => {
                  e.stopPropagation();
                  if (typeof children[0] === 'string') {
                    const text = children[0];
                    onCodeShare(text);
                  }
                };
                const shareButton = (
                  <IconButton
                    onClick={onShare}
                    sx={{
                      borderRadius: 8,
                      textTransform: 'initial',
                    }}
                  >
                    {Capacitor.getPlatform() === 'ios' ? (
                      <IosShare />
                    ) : (
                      <Share />
                    )}
                  </IconButton>
                );
                const onCopy = (e: any) => {
                  e.stopPropagation();
                  setCopied(true);

                  if (typeof children[0] === 'string') {
                    const text = children[0];
                    navigator.clipboard.writeText(text);
                  }

                  setTimeout(() => {
                    setCopied(false);
                  }, 1000);
                };
                const copyButton = (
                  <IconButton
                    onClick={onCopy}
                    sx={{
                      borderRadius: 8,
                      textTransform: 'initial',
                    }}
                  >
                    {copied ? <Check color="success" /> : <ContentCopy />}
                  </IconButton>
                );
                const topBar = (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor:
                        theme.palette.mode === 'light' ? '#E9E9EB' : '#2D2D2D',
                      borderTopLeftRadius: 7,
                      borderTopRightRadius: 7,
                      border: '2px solid',
                      px: 1.5,
                      py: 1,
                    }}
                  >
                    <Typography
                      fontWeight={600}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {maybeLanguage}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                      {Capacitor.isNativePlatform() ? shareButton : copyButton}
                    </Box>
                  </Box>
                );

                return !inline && match ? (
                  <Box>
                    <Box mb={0.5}>
                      {topBar}
                      <SyntaxHighlighter
                        {...props}
                        children={String(children).replace(/\n$/, '')}
                        style={darcula}
                        language={match[1]}
                        PreTag="div"
                        customStyle={{
                          borderBottomLeftRadius: '8px',
                          borderBottomRightRadius: '8px',
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <code {...props} className={className}>
                    {children}
                  </code>
                );
              },
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
}
