import {
  ActionSheet,
  ActionSheetButton,
  ActionSheetButtonStyle,
} from '@capacitor/action-sheet';
import { Toast } from '@capacitor/toast';
import { NavigateFunction } from 'react-router-dom';
import { clearChatHistory } from '../redux/domain/bot/botSlice';
import { deleteBotIdUpdated } from '../redux/domain/ui/uiSlice';
import { store } from '../redux/store';
import { Bot, BotMap } from '../types';
import { writeToClipboard } from './clipboard';
import { confirmClearHistory } from './dialog';
import { shareText } from './share';

export const showAnonymousUserActions = async (
  botMap: BotMap,
  navigate: NavigateFunction,
  toggleTheme: () => void,
  mode: 'light' | 'dark'
) => {
  const bots = Object.values(botMap);
  const options: ActionSheetButton[] = [
    {
      title: `${mode === 'dark' ? 'Light' : 'Dark'} Mode`,
    },
    {
      title: 'App Info',
    },
    {
      title: 'Cancel',
      style: ActionSheetButtonStyle.Cancel,
    },
  ];

  const result = await ActionSheet.showActions({
    title: `${bots.length} ${bots.length === 1 ? 'bot' : 'bots'}`,
    options,
  });

  const toggleThemeIndex = 0;
  const appInfoIndex = 1;

  if (result.index === toggleThemeIndex) {
    await toggleTheme();
  } else if (result.index === appInfoIndex) {
    navigate('/app-info');
  }
};

export const showPlaygroundActions = async (
  navigate: NavigateFunction,
  toggleTheme: () => void,
  mode: 'light' | 'dark',
  clearHistory: () => void
) => {
  const options: ActionSheetButton[] = [
    {
      title: `${mode === 'dark' ? 'Light' : 'Dark'} Mode`,
    },
    {
      title: 'Clear History',
      style: ActionSheetButtonStyle.Destructive,
    },
    {
      title: 'Cancel',
      style: ActionSheetButtonStyle.Cancel,
    },
  ];

  const result = await ActionSheet.showActions({
    title: 'Playground Actions',
    options,
  });

  const toggleThemeIndex = 0;
  const clearHistoryIndex = 1;

  if (result.index === toggleThemeIndex) {
    await toggleTheme();
  } else if (result.index === clearHistoryIndex) {
    clearHistory();
  }
};

export const showBotActions = async (
  bot: Bot,
  navigate: NavigateFunction,
  pathname: string
) => {
  const hasHistory = Object.keys(bot.chatHistory).length > 0;
  const options: ActionSheetButton[] = [
    {
      title: 'View History',
    },
    {
      title: 'Edit Bot',
    },
    {
      title: 'Copy Bot',
    },
  ];

  if (hasHistory) {
    options.push({
      title: 'Clear History',
    });
  }

  options.push({
    title: 'Delete Bot',
    style: ActionSheetButtonStyle.Destructive,
  });
  options.push({
    title: 'Cancel',
    style: ActionSheetButtonStyle.Cancel,
  });

  const result = await ActionSheet.showActions({
    title: bot.name,
    message: `${Object.keys(bot.chatHistory).length} messages`,
    options,
  });

  const viewHistoryIndex = 0;
  const editBotIndex = 1;
  const copyBotIndex = 2;
  const clearHistoryIndex = hasHistory ? 3 : -1;
  const deleteBotIndex = hasHistory ? 4 : 3;

  if (result.index === viewHistoryIndex) {
    navigate(`/bot-history/${bot.id}`);
  } else if (result.index === editBotIndex) {
    navigate('/edit-bot', { state: { bot } });
  } else if (result.index === copyBotIndex) {
    navigate('/copy-bot', { state: { bot } });
  } else if (result.index === clearHistoryIndex) {
    const clearHistory = () => store.dispatch(clearChatHistory(bot.id));
    await confirmClearHistory(clearHistory, bot);
  } else if (result.index === deleteBotIndex) {
    if (pathname === '/') {
      store.dispatch(deleteBotIdUpdated(bot.id));
    } else {
      navigate('/delete-bot', { state: { bot } });
    }
  }
};

export const showBotMessageActions = async (content: string) => {
  const options: ActionSheetButton[] = [
    {
      title: 'Share',
    },
    {
      title: 'Copy',
    },
    {
      title: 'Cancel',
      style: ActionSheetButtonStyle.Cancel,
    },
  ];

  const result = await ActionSheet.showActions({
    message: content,
    options,
  });

  if (result.index === 0) {
    await shareText(content);
  } else if (result.index === 1) {
    await writeToClipboard(content);
    await Toast.show({
      text: 'Copied!',
      duration: 'short',
      position: 'bottom',
    });
  }
};

export const showSystemPromptActions = async (content: string) => {
  const options: ActionSheetButton[] = [
    {
      title: 'Edit',
    },
    {
      title: 'Copy',
    },
    {
      title: 'Cancel',
      style: ActionSheetButtonStyle.Cancel,
    },
  ];

  const result = await ActionSheet.showActions({
    title: 'System Prompt',
    message: content,
    options,
  });

  if (result.index === 0) {
    // Handled via result
  } else if (result.index === 1) {
    await writeToClipboard(content);
    await Toast.show({
      text: 'Copied!',
      duration: 'short',
      position: 'bottom',
    });
  }

  return result.index;
};
