import { Capacitor } from '@capacitor/core';
import { Fullscreen } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  TextareaAutosize,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useAIStreamedAnswer } from '../hooks/useAIStreamedAnswer';
import { streamFinished } from '../redux/domain/app/appSlice';
import {
  botDeleted,
  currentBotIdUpdated,
  upsertIntoChatHistory,
} from '../redux/domain/bot/botSlice';
import { promptSubmitted } from '../redux/domain/playground/playgroundSlice';
import { deleteBotIdUpdated } from '../redux/domain/ui/uiSlice';
import { Bot } from '../types';
import BotAvatar from './BotAvatar';
import BotMenu from './BotMenu';
import PlaygroundMessage from './PlaygroundMessage';

const recordedIds: Record<string, boolean> = {};
interface BotWidgetProps {
  bot: Bot;
}
export default function BotWidget({ bot }: BotWidgetProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { id, response, startStream, done, prompt } = useAIStreamedAnswer();
  const history = useAppSelector((s) => s.playground.history);
  const [hideResponse, setHideResponse] = useState(false);

  const deleteBotId = useAppSelector((s) => s.ui.deleteBotId);

  useEffect(() => {
    if (id && done && !recordedIds[id]) {
      setHideResponse(false);
      const existing = history.find((h) => h.id === id);
      if (!existing && prompt && response) {
        dispatch(promptSubmitted({ id, prompt, response }));
        dispatch(streamFinished());
        dispatch(
          upsertIntoChatHistory({
            botId: bot.id,
            message: {
              created_ts: Date.now(),
              updated_ts: Date.now(),
              id: Date.now().toString(),
              prompt: prompt,
              type: 'message' as const,
              content: response,
            },
          })
        );
        recordedIds[id] = true;
      }
    }
  }, [bot.id, dispatch, done, history, id, prompt, response]);

  const [passthrough, setPassthrough] = useState('');

  const [showBorderStyles, setShowBorderStyles] = useState(false);

  const maybeBorderStyles = useMemo(
    () =>
      showBorderStyles
        ? {
            borderColor: theme.palette.primary.main,
          }
        : {},
    [showBorderStyles, theme.palette.primary.main]
  );

  const [justSubmitted, setJustSubmitted] = useState(false);
  const isStreaming = Boolean(response) && !done;
  const disablePassthrough = !passthrough || isStreaming || justSubmitted;
  const combined = `${bot.currentSystemPrompt}\n${passthrough}`;

  const streamCb = useCallback(async () => {
    setJustSubmitted(true);
    setHideResponse(false);
    await startStream(combined);
    setJustSubmitted(false);
  }, [combined, startStream]);

  const isHidingPrompt = useAppSelector(
    (s) => s.bot.botVisMap[bot.id] === false
  );

  const textarea = (
    <TextareaAutosize
      data-testid="bot-widget-textarea"
      onBlur={() => {
        setShowBorderStyles(false);
      }}
      onFocus={(e) => {
        setShowBorderStyles(true);
      }}
      onKeyDown={async (e) => {
        const isMobile = Capacitor.isNativePlatform();
        if (!isMobile && e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          if (!disablePassthrough) {
            await streamCb();
          }
        }
      }}
      style={{
        borderRadius: '0.5rem',
        marginTop: '4px',
        fontSize: '18px',
        padding: '1rem',
        outline: 'none',
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        ...maybeBorderStyles,
      }}
      maxRows={9}
      minRows={3}
      onChange={(e) => setPassthrough(e.target.value)}
      value={passthrough}
    />
  );

  const buttons = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        gap: 1,
        mt: 1,
      }}
    >
      <LoadingButton
        loading={isStreaming || justSubmitted}
        sx={{ borderRadius: '2rem' }}
        variant="contained"
        disabled={disablePassthrough}
        onClick={streamCb}
      >
        Submit
      </LoadingButton>
      <Button
        sx={{ borderRadius: '2rem' }}
        disabled={!passthrough}
        onClick={() => {
          setPassthrough('');
          setHideResponse(true);
        }}
        variant="outlined"
        color="inherit"
      >
        Clear
      </Button>
    </Box>
  );

  const onConfirmDeleteBot = useCallback(async () => {
    dispatch(botDeleted(bot.id));
  }, [bot, dispatch]);

  const deleteBotForm = (
    <>
      <Box sx={{ py: 1 }}>
        <Alert
          icon={<></>}
          variant="standard"
          color="error"
          sx={{ borderRadius: '0.5rem', p: 0 }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">This bot will be lost forever.</Typography>
          </Box>
        </Alert>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Button
          color="inherit"
          sx={{ borderRadius: '2rem' }}
          onClick={() => {
            dispatch(deleteBotIdUpdated(undefined));
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          sx={{ borderRadius: '2rem' }}
          color="error"
          variant="contained"
          onClick={onConfirmDeleteBot}
        >
          Delete
        </Button>
      </Box>
    </>
  );

  const botHeader = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <BotAvatar bot={bot} sx={{ width: 50, height: 50 }} />
        <Typography
          sx={{
            userSelect: 'none',
            fontSize: '20px',
            fontFamily: 'Lato',
            fontWeight: 600,
          }}
        >
          {bot.name}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          onClick={() => {
            dispatch(currentBotIdUpdated(bot.id));
            navigate(`/bots/${bot.id}`);
          }}
        >
          <Fullscreen />
        </IconButton>
        <BotMenu iconColor="primary" bot={bot} />
      </Box>
    </Box>
  );

  const content = (
    <Box sx={{ width: '100%' }}>
      {isHidingPrompt ? null : (
        <Typography color="text.secondary">
          {bot.currentSystemPrompt}
        </Typography>
      )}
      {textarea}
      {buttons}
      {response && !hideResponse ? (
        <>
          {isHidingPrompt ? null : (
            <Box>
              <Divider>
                <Typography variant="caption">PROMPT</Typography>
              </Divider>
              <Typography sx={{ color: 'text.secondary', p: 0.5 }}>
                {prompt}
              </Typography>
            </Box>
          )}
          <Box key={`${id}-${done ? 'done' : 'inprogress'}`}>
            <PlaygroundMessage content={response} />
          </Box>
        </>
      ) : null}
    </Box>
  );

  return (
    <Box sx={{ p: 1, width: '100%' }}>
      {botHeader}
      {bot.id === deleteBotId ? deleteBotForm : content}
    </Box>
  );
}
