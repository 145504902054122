import { Box, Link, Typography } from '@mui/material';

const content = `
This Privacy Policy ("Policy") governs the collection, use, disclosure, and management of user data by Dog Day Apps, LLC ("we," "us," or "our") in relation to your use of the Darn Bots application ("Darn Bots" or "App"). It is essential that you carefully read and understand this Policy as it outlines our practices regarding your personal information and how we handle and safeguard it. By using Darn Bots, you explicitly consent to the collection, use, and disclosure of your personal information as described herein.

1- Collection of Personal Information
1.1 User Provided Information:
When you use Darn Bots, we may collect personal information directly from you. This includes but is not limited to information such as your name, email address, and contact details that you provide to us voluntarily.

1.2 Chat Data:
In the course of utilizing Darn Bots, your interactions and conversations with the AI chat bots generate data. It is important to note that these messages, including their associated data, may be transmitted to Open AI and other service providers' servers to enable the AI-powered chat bot functionality.

1.3 Usage Information: 
We may automatically collect information regarding your use of Darn Bots, including the features accessed, content viewed, and actions taken. This information aids us in understanding your interactions with the App, enhancing its performance, and improving the overall user experience.

1.4 Device and Log Information:
We may gather certain information about the device you use to access Darn Bots, including device type, operating system version, unique device identifiers, IP address, and crash logs, to ensure optimal functionality and diagnose technical issues.

2- Use of Personal Information
2.1 Provision and Improvement of Darn Bots:
We utilize the personal information collected to provide and maintain Darn Bots, enhance its features and functionality, and personalize your experience. Furthermore, we may employ this information to respond to your inquiries, fulfill your requests, and communicate with you regarding updates and developments concerning the App.

2.2 AI Model Customization:
Darn Bots empowers users to tailor generative AI chat bots according to their requirements by leveraging prompt duplication and refinement features. Your personal information, including your chat data and usage information, may be utilized to enhance and train the AI models, resulting in a more personalized and effective experience for you.

2.3 Legal Compliance and Protection:
We may employ your personal information to ensure compliance with applicable laws, regulations, legal processes, or governmental requests. Additionally, we may use the information to protect the rights, privacy, safety, or property of Dog Day Apps, LLC, its users, and the general public.

3- Disclosure of Personal Information
3.1 Service Providers:
We may share your personal information with trusted third-party service providers who assist us in the provision and improvement of Darn Bots. These service providers are bound by strict confidentiality obligations and are authorized to process your information solely for the purposes specified by us.

3.2 Business Transfers:
In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity or third party as part of the transaction. We undertake to take appropriate measures to maintain the confidentiality and security of your personal information during such transfers.

3.3 Legal Requirements: We may disclose your personal information if required to do so by law or if we believe, in good faith, that such disclosure is necessary to protect our rights, comply with a judicial proceeding, court order, or legal process, or to prevent imminent harm or financial loss.

4- Data Security
We employ reasonable organizational, technical, and administrative measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.

5- Third-Party Links and Services
Darn Bots may contain links to third-party websites, services, or applications that are not operated or controlled by us. This Policy does not apply to such third-party services, and we assume no responsibility for their privacy practices. We strongly recommend reviewing the privacy policies of these third-party services before providing any personal information to them.

6- Children's Privacy
Darn Bots is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you become aware that your child has provided us with personal information without your consent, please contact us using the information provided in Section 8.

7- Changes to this Policy
We reserve the right to modify this Policy from time to time to reflect changes in our information practices. Any material changes to the Policy will be communicated by posting an updated version on our website or through other appropriate means. Your continued use of Darn Bots after the effective date of the updated Policy constitutes your acceptance of the revised terms.

8- Contact Us
If you have any questions, concerns, or wish to exercise your rights regarding your personal information under applicable data protection laws, please contact us at
`;

export default function PrivacyPolicyContent() {
  return (
    <Box>
      {content.split('\n').map((line, index) => {
        const headingPrefixes = ['1-', '2-', '3-', '4-', '5-', '6-', '7-'];
        const isHeading = headingPrefixes.some((prefix) =>
          line.startsWith(prefix)
        );

        if (isHeading) {
          return (
            <Typography
              key={`heading-line-${index}`}
              sx={{ my: 1 }}
              variant="h5"
            >
              {line}
            </Typography>
          );
        }
        const subheadingPrefixes = ['1.', '2.', '3.'];
        const isSubheading = subheadingPrefixes.some((prefix) =>
          line.startsWith(prefix)
        );
        if (isSubheading) {
          return (
            <Typography
              key={`subheading-line-${index}`}
              sx={{ my: 1 }}
              variant="h6"
            >
              {line}
            </Typography>
          );
        }
        return (
          <Typography key={`line-${index}`} sx={{ my: 1 }}>
            {line}
          </Typography>
        );
      })}
      <Link href="mailto:support@dogdayapps.com">support@dogdayapps.com</Link>
    </Box>
  );
}
