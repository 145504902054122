import { Share } from '@capacitor/share';
import { showShortToast } from './toast';

const DEBUG = false;

export const canShare = async () => {
  //   if (!Capacitor.isNativePlatform()) {
  //     // Allow web sharing when support for https://web.dev/web-share/ is less spotty.
  //     return false;
  //   }
  const { value: canShare } = await Share.canShare();
  return canShare;
};

export const shareDarnBots = async () => {
  try {
    const result = await Share.share({
      title: 'Darn Bots',
      text: 'Build your own darn bot',
      url: 'https://darnbots.com/',
      dialogTitle: 'Share Darn Bots with others',
    });

    if (DEBUG) {
      showShortToast(`Shared with ${result.activityType}`);
    }
  } catch (e) {
    // no-op
  }
};

export const shareText = async (text: string) => {
  try {
    const result = await Share.share({
      text,
    });

    if (DEBUG) {
      showShortToast(`Shared with ${result.activityType}`);
    }
  } catch (e) {
    // no-op
  }
};
