import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { Avatar, SxProps } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getStaticUrl } from '../helpers/staticHelpers';
import { getPhotoFromCamera } from '../mobile/camera';
import { Bot } from '../types';

type PartialBot = Pick<Bot, 'id' | 'name'>;

const getBase64FromUrl = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const DEFAULT_AVATAR = getStaticUrl('/icons/waveBot.svg');

const loadAvatar = async (bot: PartialBot) => {
  const { value: avatarUrl } = await Preferences.get({
    key: `${bot.id}-avatar`,
  });
  if (avatarUrl) {
    return avatarUrl;
  }
};

const saveAvatar = async (bot: PartialBot, avatarUrl: string) => {
  await Preferences.set({
    key: `${bot.id}-avatar`,
    value: avatarUrl,
  });
};

interface BotAvatarProps {
  bot: PartialBot;
  sx?: SxProps;
}
export default function BotAvatar({ bot, sx }: BotAvatarProps) {
  const { name } = bot;

  const styles = { width: 42, height: 42, mr: 1, ...sx };

  const [avatar, setAvatar] = useState<string>(DEFAULT_AVATAR);

  useEffect(() => {
    const load = async () => {
      const maybeAvatar = await loadAvatar(bot);
      if (maybeAvatar) {
        setAvatar(maybeAvatar);
      }
    };
    load();
  }, [bot]);

  const onAvatarClick = useCallback(
    async (e: React.MouseEvent) => {
      if (Capacitor.getPlatform() === 'web') {
        // TODO: allow web to update the image
        return;
      }
      e.stopPropagation();
      const maybePhotoUrl = await getPhotoFromCamera();
      if (maybePhotoUrl) {
        const base64 = await getBase64FromUrl(maybePhotoUrl);
        if (typeof base64 !== 'string') {
          throw new Error('Expected base64 to be a string');
        }
        setAvatar(base64);
        saveAvatar(bot, base64);
      }
    },
    [bot]
  );

  const customAvatar = (
    <Avatar
      alt={`${name} avatar`}
      onClick={onAvatarClick}
      src={avatar}
      sx={styles}
    />
  );

  return customAvatar;
}
