import { Capacitor } from '@capacitor/core';
import { Box, Typography } from '@mui/material';
import { useCallback, useRef } from 'react';
import { showBotMessageActions } from '../mobile/actionSheet';

export default function UserMessage({ content }: { content: string }) {
  const pressTimer = useRef<NodeJS.Timeout>();

  const handlePointerDown = useCallback(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    pressTimer.current = setTimeout(async () => {
      await showBotMessageActions(content);
    }, 300);
  }, [content]);

  const handlePointerUp = () => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    clearTimeout(pressTimer.current);
  };

  return (
    <Box
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerUp}
      sx={{
        userSelect: Capacitor.isNativePlatform() ? 'none' : undefined,
        display: 'flex',
        flexDirection: 'row-reverse',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#438BF6',
          px: 2,
          py: 1,
          width: 'fit-content',
          borderRadius: 4,
          ml: 2,
          my: 0.5,
        }}
      >
        <Typography color="white">{content}</Typography>
      </Box>
    </Box>
  );
}
