import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const footerLinkStyles = {
  color: 'whitesmoke',
  textDecoration: 'none',
  fontSize: '14px',
};

export default function WebFooter() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const { pathname } = useLocation();

  const noFooterPathnames = ['/bots'];
  const shouldHideFooter = Boolean(
    noFooterPathnames.find((prefix) => pathname.startsWith(prefix))
  );
  if (shouldHideFooter) {
    return null;
  }

  return (
    <div className="footer">
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          width: '100%',
        }}
      >
        <Box
          sx={{
            p: { xs: 0, md: 1 },
            backgroundColor: isDarkMode ? '#33363A' : 'primary.main',
          }}
        >
          <Stack>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: { xs: 1, md: 2 },
                p: 0.5,
              }}
            >
              <Link style={footerLinkStyles} to="/about">
                About
              </Link>
              <Link style={footerLinkStyles} to="/privacy">
                Privacy
              </Link>
              <Link style={footerLinkStyles} to="/terms">
                Terms
              </Link>
              <Link style={footerLinkStyles} to="/support">
                Support
              </Link>
            </Box>
            <Typography
              sx={{
                p: 0.5,
                color: 'whitesmoke',
                textAlign: 'center',
              }}
              variant="body2"
            >
              © 2024 Dog Day Apps, LLC
            </Typography>
          </Stack>
        </Box>
      </Box>
    </div>
  );
}
