import { Capacitor } from '@capacitor/core';
import { History } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Badge,
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useAIStreamedAnswer } from '../hooks/useAIStreamedAnswer';
import {
  modelUpdated,
  promptSubmitted,
  streamFinished,
} from '../redux/domain/playground/playgroundSlice';
import { AIModel } from '../types';
import PlaygroundMessage from './PlaygroundMessage';

const recordedIds: Record<string, boolean> = {};
export default function PlaygroundWidget() {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentPrompt, setCurrentPrompt] = useState('');
  const { id, response, startStream, done, prompt } = useAIStreamedAnswer();
  const history = useAppSelector((s) => s.playground.history);
  const [hideLatestResponse, setHideLatestResponse] = useState(false);

  const historyButton = (
    <IconButton
      disabled={history.length === 0}
      onClick={(e) => {
        e.stopPropagation();
        navigate('/playground-history');
      }}
    >
      <Badge
        badgeContent={history.length}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ color: 'inherit' }}
      >
        <History
          sx={{
            color: isDark
              ? history.length === 0
                ? undefined
                : 'white'
              : history.length === 0
              ? undefined
              : 'rgba(0, 0, 0, 0.87)',
            mb: 0.25,
          }}
        />
      </Badge>
    </IconButton>
  );

  useEffect(() => {
    if (id && done && !recordedIds[id]) {
      const existing = history.find((h) => h.id === id);
      if (!existing && prompt && response) {
        dispatch(promptSubmitted({ id, prompt, response }));
        dispatch(streamFinished());
        recordedIds[id] = true;
      }
    }
  }, [dispatch, done, history, id, prompt, response]);

  const [passthrough, setPassthrough] = useState('');

  const [showBorderStyles, setShowBorderStyles] = useState(false);

  const maybeBorderStyles = useMemo(
    () =>
      showBorderStyles
        ? {
            borderColor: theme.palette.primary.main,
          }
        : {},
    [showBorderStyles, theme.palette.primary.main]
  );

  const [justSubmitted, setJustSubmitted] = useState(false);
  const isStreaming = Boolean(response) && !done;
  const disablePassthrough = !passthrough || isStreaming || justSubmitted;

  const streamCb = useCallback(async () => {
    setHideLatestResponse(false);
    setJustSubmitted(true);
    setCurrentPrompt(passthrough);
    await startStream(passthrough);
    setJustSubmitted(false);
  }, [passthrough, startStream]);

  const model = useAppSelector((s) => s.playground.model);
  const modelSelect = (
    <Select
      value={model}
      onChange={(e) => {
        dispatch(modelUpdated(e.target.value as AIModel));
      }}
      size="small"
      fullWidth
      MenuProps={{
        sx: {
          mt: 0.75,
        },
      }}
    >
      <Typography sx={{ p: 1, color: 'text.secondary' }} variant="body2">
        Model
      </Typography>
      <MenuItem value={AIModel['gpt-4o-mini']}>GPT-4o-mini</MenuItem>
      <MenuItem value={AIModel['gpt-4o']}>GPT-4o</MenuItem>
      <MenuItem value={AIModel['gpt-4-turbo']}>GPT-4 Turbo</MenuItem>
    </Select>
  );

  const textarea = (
    <TextareaAutosize
      data-testid="playground-widget-textarea"
      autoFocus={!Capacitor.isNativePlatform()}
      onBlur={() => {
        setShowBorderStyles(false);
      }}
      onFocus={(e) => {
        setShowBorderStyles(true);
      }}
      onKeyDown={async (e) => {
        const isMobile = Capacitor.isNativePlatform();
        if (!isMobile && e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          if (!disablePassthrough) {
            await streamCb();
          }
        }
      }}
      placeholder="Test your prompts here."
      style={{
        borderRadius: '0.5rem',
        fontSize: '18px',
        padding: '1rem',
        outline: 'none',
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        backgroundColor: theme.palette.background.paper,
        ...maybeBorderStyles,
      }}
      maxRows={9}
      minRows={4}
      onChange={(e) => setPassthrough(e.target.value)}
      value={passthrough}
    />
  );

  const buttons = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        gap: 1,
        mt: 1,
      }}
    >
      <LoadingButton
        loading={isStreaming || justSubmitted}
        sx={{ borderRadius: '2rem' }}
        variant="contained"
        disabled={disablePassthrough}
        onClick={streamCb}
      >
        Submit
      </LoadingButton>
      <Button
        sx={{ borderRadius: '2rem' }}
        disabled={!passthrough}
        onClick={() => {
          setPassthrough('');
          setHideLatestResponse(true);
        }}
        variant="outlined"
        color="inherit"
      >
        Clear
      </Button>
    </Box>
  );

  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '2rem',
        overflowX: 'auto',
        width: '100%',
      }}
    >
      <Stack width="100%" spacing={2}>
        <Stack width="100%" spacing={1}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                userSelect: 'none',
                fontSize: '20px',
                fontFamily: 'Lato',
                cursor: 'pointer',
                fontWeight: 600,
              }}
            >
              Playground
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {historyButton}
            </Box>
          </Box>
          {modelSelect}
          {textarea}
          {buttons}
        </Stack>
        {hideLatestResponse ? null : (
          <Box>
            <Typography sx={{ color: 'text.secondary', p: 0.5 }}>
              {currentPrompt}
            </Typography>
            <PlaygroundMessage content={response} />
          </Box>
        )}
      </Stack>
    </Box>
  );
}
