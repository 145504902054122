import { Capacitor } from '@capacitor/core';
import axios from 'axios';
import { showLongToast } from '../mobile/toast';

export const getStaticUrl = (pathname: string) => {
  return `${process.env.REACT_APP_STATIC_URL}${pathname}`;
};

export const getJSON = async (pathname: string) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: getStaticUrl(pathname),
    });

    return data;
  } catch (error) {
    if (Capacitor.isNativePlatform()) {
      showLongToast(
        JSON.stringify(error) + ' ' + pathname + ' ' + getStaticUrl(pathname)
      );
    }
    console.log(error);
  }
};
