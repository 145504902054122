import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../hooks';
import { Bot } from '../types';
import BotAvatar from './BotAvatar';
import BotMenu from './BotMenu';

export default function BotPreview2({ bot: botProp }: { bot: Bot }) {
  const botMap = useAppSelector((s) => s.bot.botMap);
  const bot = botMap[botProp.id];

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <BotAvatar bot={bot} sx={{ width: 64, height: 64 }} />
        <Box>
          <Typography variant="h6">{bot.name}</Typography>
          <Typography color="text.secondary">
            {Object.keys(bot.chatHistory).length} messages
          </Typography>
        </Box>
      </Box>
      <BotMenu iconColor="primary" bot={bot} />
    </Box>
  );
}
