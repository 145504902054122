import { InfoOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import * as React from 'react';

interface InfoDrawerProps {
  'data-testid': string;
  content: React.ReactNode | string;
}
export default function InfoDrawer({
  'data-testid': dataTestId,
  content,
}: InfoDrawerProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment key={dataTestId}>
      <IconButton onClick={() => setOpen(!open)}>
        <InfoOutlined color="primary" fontSize="small" />
      </IconButton>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        {content}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
