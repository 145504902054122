import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useAppSelector } from '../hooks';
import { AIModel } from '../types';

const getStreamUrl = (prompt: string, model: AIModel) => {
  const searchParams = new URLSearchParams();
  searchParams.set('prompt', prompt);
  searchParams.set('model', model);

  const searchQuery = searchParams.toString();
  return process.env.REACT_APP_CHAT_COMPLETION_FN + `?${searchQuery}`;
};

const prompts: Record<string, string> = {};
const getId = (prompt: string) => {
  if (prompts[prompt]) {
    return prompts[prompt];
  }
  const id = uuid();
  prompts[prompt] = id;
  return id;
};

const streamResponses: Record<string, string> = {};
export const useAIStreamedAnswer = (): {
  id: string;
  prompt: string;
  done: boolean;
  response: string;
  startStream: (prompt: string) => Promise<void>;
} => {
  const [id, setId] = useState('');
  const [done, setDone] = useState(false);
  const [streamResponse, setStreamResponse] = useState('');
  const [finalResponse, setFinalResponse] = useState('');
  const [prompt, setPrompt] = useState('');
  const model = useAppSelector((s) => s.playground.model);

  const startStream = useCallback(
    async (prompt: string) => {
      setId(getId(prompt));
      setPrompt(prompt);
      setStreamResponse('');
      setFinalResponse('');
      const response = await fetch(getStreamUrl(prompt, model));
      if (!response.body) {
        return;
      }
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      let streamResponse = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          setDone(true);
          streamResponses[id] = streamResponse;
          setFinalResponse(streamResponse);
          return;
        }
        streamResponse += value;
        setStreamResponse(streamResponse);
        setDone(false);
      }
    },
    [id, model]
  );

  if (!finalResponse) {
    return { id, prompt, response: streamResponse, startStream, done };
  }

  return { id, prompt, response: finalResponse, startStream, done };
};
