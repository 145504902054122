import { Capacitor } from '@capacitor/core';
import {
  Box,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getCurrentBot,
  settingsUpdated,
} from '../../redux/domain/bot/botSlice';
import { focusEditBotSystemPromptUpdated } from '../../redux/domain/ui/uiSlice';
import { Bot } from '../../types';
import BotAvatar from '../BotAvatar';
import InfoDrawerSystemPrompt from '../navigation/InfoDrawerSystemPrompt';

const EditBotPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const bot: Bot | undefined = location.state?.bot;
  const currentBot = useAppSelector(getCurrentBot);
  const finalBot = bot || currentBot;

  const {
    name: defaultName = '',
    currentSystemPrompt: defaultSystemPrompt = '',
  } = finalBot || {};

  const [name, setName] = useState(defaultName);
  const [systemPrompt, setSystemPrompt] = useState(defaultSystemPrompt);

  const ref = useRef<HTMLTextAreaElement | null>(null);
  const focusEditBotSystemPrompt = useAppSelector(
    (s) => s.ui.focusEditBotSystemPrompt
  );

  const [showBorderStyles, setShowBorderStyles] = useState<
    'name' | 'prompt' | undefined
  >(undefined);

  useEffect(() => {
    if (focusEditBotSystemPrompt) {
      dispatch(focusEditBotSystemPromptUpdated(false));
      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [dispatch, focusEditBotSystemPrompt]);

  if (!finalBot) {
    return null;
  }

  const nameBorderStyles =
    showBorderStyles === 'name'
      ? {
          border: '1.5px solid',
          borderColor: theme.palette.primary.main,
        }
      : {};

  const promptBorderStyles =
    showBorderStyles === 'prompt'
      ? {
          border: '1.5px solid',
          borderColor: theme.palette.primary.main,
        }
      : {};

  const systemPromptInput = (
    <Stack>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Typography sx={{ fontWeight: 600 }}>System Prompt</Typography>
        <InfoDrawerSystemPrompt />
      </Box>
      <TextareaAutosize
        onBlur={() => {
          setShowBorderStyles(undefined);
          dispatch(
            settingsUpdated({
              name,
              systemPrompt,
              botId: finalBot.id,
            })
          );
        }}
        onFocus={(e) => {
          setShowBorderStyles('prompt');
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
          e.currentTarget.scrollIntoView({ behavior: 'auto' });
        }}
        style={{
          marginTop: '4px',
          fontSize: '18px',
          borderRadius: '1rem',
          padding: '1rem',
          outline: 'none',
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          backgroundColor: theme.palette.background.paper,
          ...promptBorderStyles,
        }}
        minRows={3}
        maxRows={9}
        onChange={(e) => setSystemPrompt(e.target.value)}
        value={systemPrompt}
        ref={ref}
      />
    </Stack>
  );

  const showAvatar = Capacitor.isNativePlatform() && bot;

  const nameWithMaybeAvatar = showAvatar ? (
    <Box
      sx={{ display: 'flex', alignItems: 'center', gap: 0.5, width: '100%' }}
    >
      {Capacitor.isNativePlatform() ? (
        <BotAvatar
          bot={bot}
          sx={{
            width: 80,
            height: 80,
          }}
        />
      ) : null}
      <TextField
        onBlur={() => {
          dispatch(
            settingsUpdated({
              name,
              systemPrompt,
              botId: finalBot.id,
            })
          );
        }}
        fullWidth
        InputProps={{
          sx: { borderRadius: '1rem', fontSize: '20px', ...nameBorderStyles },
        }}
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
    </Box>
  ) : (
    <Stack>
      <Typography sx={{ px: 1, fontWeight: 600 }}>Name</Typography>
      <TextField
        onBlur={() => {
          dispatch(
            settingsUpdated({
              name,
              systemPrompt,
              botId: finalBot.id,
            })
          );
        }}
        InputProps={{
          sx: { borderRadius: '1rem', fontSize: '20px', ...nameBorderStyles },
        }}
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
    </Stack>
  );

  return (
    <Box sx={{ px: 2 }}>
      <Stack spacing={2} sx={{ py: 1 }}>
        {nameWithMaybeAvatar}
        {systemPromptInput}
      </Stack>
    </Box>
  );
};

export default EditBotPage;
