import { Box, Divider, Stack, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  botCreated,
  currentBotIdUpdated,
} from '../../redux/domain/bot/botSlice';

const ProgrammingWizardPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const programmingLanguages = useAppSelector((s) =>
    Object.values(s.app.lists.programmingLanguages)
  );

  return (
    <Box>
      <Stack divider={<Divider sx={{ my: 1 }} />}>
        {programmingLanguages.map((programmingLanguage) => {
          const codingBackTicks = '```';
          const codingPreamble = `Preface all code samples with ${codingBackTicks} and the programming language.`;
          const name = `${programmingLanguage} Bot`;
          const systemPrompt = `${codingPreamble} Write me a ${programmingLanguage} program that `;

          return (
            <Stack
              onClick={() => {
                const newId = uuid();
                dispatch(
                  botCreated({
                    chatHistory: {},
                    id: newId,
                    name,
                    currentSystemPrompt: systemPrompt,
                    streamResponse: '',
                    systemPromptHistory: { [Date.now()]: systemPrompt },
                    created_ts: Date.now(),
                    updated_ts: Date.now(),
                  })
                );
                dispatch(currentBotIdUpdated(newId));
                navigate(`/bots/${newId}`);
              }}
              sx={{
                p: 1,
                cursor: 'pointer',
              }}
              key={programmingLanguage}
            >
              <Typography fontWeight={600} variant="h6">
                {programmingLanguage}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
      <Divider sx={{ my: 1 }} />
    </Box>
  );
};

export default ProgrammingWizardPage;
