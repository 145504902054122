import { Box, Divider, Stack, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  botCreated,
  currentBotIdUpdated,
} from '../../redux/domain/bot/botSlice';

const SubjectsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const subjects = useAppSelector((s) => Object.values(s.app.lists.subjects));

  return (
    <Box>
      <Stack divider={<Divider sx={{ my: 1 }} />}>
        {subjects.map((subject) => {
          const name = `${subject} Tutor`;
          const systemPrompt = `I will ask you questions or state a topic related to ${subject}. Provide a concise, accurate answer. I'd like to know about `;

          return (
            <Stack
              onClick={() => {
                const newId = uuid();
                dispatch(
                  botCreated({
                    chatHistory: {},
                    id: newId,
                    name,
                    currentSystemPrompt: systemPrompt,
                    streamResponse: '',
                    systemPromptHistory: { [Date.now()]: systemPrompt },
                    created_ts: Date.now(),
                    updated_ts: Date.now(),
                  })
                );
                dispatch(currentBotIdUpdated(newId));
                navigate(`/bots/${newId}`);
              }}
              sx={{
                p: 1,
                cursor: 'pointer',
              }}
              key={subject}
            >
              <Typography fontWeight={600} variant="h6">
                {subject}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
      <Divider sx={{ my: 1 }} />
    </Box>
  );
};

export default SubjectsPage;
