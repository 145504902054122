import { Capacitor } from '@capacitor/core';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import AppRouter from './AppRouter';
import GetMobileInfo from './components/behavior/GetMobileInfo';
import { setStatusBarStyleDark } from './mobile/statusBar';

import { useMediaQuery } from '@mui/material';
import Mousetrap from 'mousetrap';
import { useAppDispatch, useAppSelector } from './hooks';
import PromptForAppRating from './PromptForAppRating';
import { modeUpdated } from './redux/domain/ui/uiSlice';
import { darkTheme, lightTheme } from './theme/theme';

const MobileSpecificComponents = () => {
  // Mobile-specific side effects
  useEffect(() => {
    const configureStatusBar = async () => {
      await setStatusBarStyleDark();
    };
    configureStatusBar();
  }, []);

  return (
    <>
      <GetMobileInfo />
      <PromptForAppRating />
    </>
  );
};

const App = () => {
  const dispatch = useAppDispatch();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const mode = useAppSelector((s) => s.ui.mode);

  const theme = useMemo(() => {
    if (!mode) {
      return prefersDarkMode ? darkTheme : lightTheme;
    }
    return mode === 'dark' ? darkTheme : lightTheme;
  }, [prefersDarkMode, mode]);

  useEffect(() => {
    Mousetrap.bind('t', () => {
      dispatch(modeUpdated(mode === 'dark' ? 'light' : 'dark'));
    });

    return () => {
      Mousetrap.unbind('t');
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRouter />
      {Capacitor.isNativePlatform() ? <MobileSpecificComponents /> : null}
    </ThemeProvider>
  );
};

export default App;
