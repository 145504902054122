import { Capacitor } from '@capacitor/core';
import { Settings } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { showAnonymousUserActions } from '../../mobile/actionSheet';
import { modeUpdated } from '../../redux/domain/ui/uiSlice';

export default function UserMenu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const botMap = useAppSelector((s) => s.bot.botMap);

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuOpen = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleTheme = useCallback(() => {
    const newMode = theme.palette.mode === 'dark' ? 'light' : 'dark';
    dispatch(modeUpdated(newMode));
    handleClose();
  }, [dispatch, theme.palette.mode]);

  const onTermsOfServiceClick = useCallback(() => {
    navigate('/terms');
    handleClose();
  }, [navigate]);

  const onPrivacyPolicyClick = useCallback(() => {
    navigate('/privacy');
    handleClose();
  }, [navigate]);

  const onAboutClick = useCallback(() => {
    navigate('/about');
    handleClose();
  }, [navigate]);

  const onSupportClick = useCallback(() => {
    navigate('/support');
    handleClose();
  }, [navigate]);

  return (
    <>
      <IconButton
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          if (Capacitor.isNativePlatform()) {
            await showAnonymousUserActions(
              botMap,
              navigate,
              toggleTheme,
              theme.palette.mode
            );
          } else {
            handleClick(e);
          }
        }}
      >
        <Settings fontSize="large" sx={{ color: 'whitesmoke' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        sx={{ mt: 1 }}
      >
        <MenuItem onClick={toggleTheme} sx={{ fontSize: '20px' }}>
          {theme.palette.mode === 'dark' ? 'Light' : 'Dark'} Mode
        </MenuItem>
        <MenuItem onClick={onAboutClick} sx={{ fontSize: '20px' }}>
          About
        </MenuItem>
        <MenuItem onClick={onTermsOfServiceClick} sx={{ fontSize: '20px' }}>
          Terms
        </MenuItem>
        <MenuItem onClick={onPrivacyPolicyClick} sx={{ fontSize: '20px' }}>
          Privacy
        </MenuItem>
        <MenuItem onClick={onSupportClick} sx={{ fontSize: '20px' }}>
          Support
        </MenuItem>
      </Menu>
    </>
  );
}
