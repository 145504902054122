import { Box, Stack, Typography } from '@mui/material';
import { useAppSelector } from '../../hooks';

export default function MobileDebugInfoPage() {
  const deviceInfo = useAppSelector((s) => s.app.mobile.device);
  const batteryInfo = useAppSelector((s) => s.app.mobile.battery);
  return (
    <Stack sx={{ p: 2 }} spacing={4}>
      <Box>
        <Typography variant="h6">Window</Typography>
        {window
          ? JSON.stringify(
              {
                innerh: window.innerHeight,
                innerw: window.innerWidth,
                outerh: window.outerHeight,
                outerw: window.outerWidth,
              },
              null,
              4
            )
          : null}
      </Box>
      <Box>
        <Typography variant="h6">Device Info</Typography>
        {deviceInfo ? JSON.stringify(deviceInfo, null, 4) : null}
      </Box>
      <Box>
        <Typography variant="h6">Battery Info</Typography>
        {batteryInfo ? JSON.stringify(batteryInfo, null, 4) : null}
      </Box>
    </Stack>
  );
}
