import { Capacitor } from '@capacitor/core';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import BotMessage from '../BotMessage';
import UserMessage from '../UserMessage';
import InfoDrawer from './InfoDrawer';

const exampleSystemPrompt = 'Write me a funny joke about ';
const exampleUserMessage = 'cats';
const exampleBotMessage =
  'Why was the cat sitting on the computer? Because it wanted to keep an eye on the mouse!';
const exampleUserMessage2 = 'dogs';
const exampleBotMessage2 =
  "Why don't dogs make good dancers? Because they have two left paws!";

export default function InfoDrawerSystemPrompt() {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const systemPromptExplanation = (
    <Box sx={{ maxHeight: '80vh', overflowY: 'scroll' }}>
      <Box
        sx={{
          pt: 2,
          backgroundColor: isDark ? '#202226' : undefined,
        }}
      >
        <Box sx={{ px: 2 }}>
          <Typography fontWeight={600} variant="h6">
            What is a System Prompt?
          </Typography>
          <Box sx={{ pl: 1, pb: 1 }}>
            <Typography color="text.secondary" variant="body1">
              - the "context" for your bot
            </Typography>
            <Typography color="text.secondary" variant="body1">
              - prepended to all of your messages
            </Typography>
            <Typography color="text.secondary" variant="body1">
              - saves you from repeating yourself every request
            </Typography>
          </Box>
        </Box>
        <Divider>
          <Typography fontWeight={600} variant="h6">
            Example
          </Typography>
        </Divider>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Typography
            sx={{
              color: 'text.secondary',
              p: 1,
              userSelect: Capacitor.isNativePlatform() ? 'none' : undefined,
            }}
          >
            {exampleSystemPrompt}
          </Typography>
          <Stack>
            <UserMessage content={exampleUserMessage} />
            <BotMessage content={exampleBotMessage} />
          </Stack>
          <Stack>
            <UserMessage content={exampleUserMessage2} />
            <BotMessage content={exampleBotMessage2} />
          </Stack>
        </Box>
      </Box>
    </Box>
  );

  return (
    <InfoDrawer
      data-testid="system-prompt-info"
      content={systemPromptExplanation}
    />
  );
}
