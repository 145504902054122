import { Box, Link, Stack, Typography } from '@mui/material';
import { getStaticUrl } from '../../helpers/staticHelpers';
import {
  BugReportFormURL,
  FeatureRequestFormURL,
} from '../../redux/domain/app/appConstants';

export default function SupportPage() {
  return (
    <Box sx={{ p: { xs: 1, md: 2 } }}>
      <Stack spacing={2}>
        <Typography variant="h4">Support</Typography>
        <Typography>
          <strong>Darn Bots</strong> is created by{' '}
          <strong>Dog Day Apps, LLC.</strong>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          <Typography>
            If you have any issues, please{' '}
            <Link href="mailto:support@dogdayapps.com">email</Link> us.
          </Typography>
        </Box>
        <Typography>
          You may also file a{' '}
          <Link href={BugReportFormURL} target="_blank">
            bug report
          </Link>{' '}
          or a{' '}
          <Link href={FeatureRequestFormURL} target="_blank">
            feature request
          </Link>{' '}
          anytime.
        </Typography>
      </Stack>
      <Box
        component="img"
        sx={{
          marginTop: '1rem',
          height: { xs: 200, md: 400 },
          width: { xs: 300, md: 500 },
        }}
        alt="Crowd of bots"
        src={getStaticUrl('/icons/crowd.svg')}
      />
    </Box>
  );
}
