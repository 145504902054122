import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Person, Receipt, SmartToy } from '@mui/icons-material';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { showSystemPromptActions } from '../../mobile/actionSheet';
import { getCurrentBot } from '../../redux/domain/bot/botSlice';
import {
  focusEditBotSystemPromptUpdated,
  isShowingKeyboardUpdated,
  scrollToEndOfChatUpdated,
} from '../../redux/domain/ui/uiSlice';
import ChatInput from '../ChatInput';

const MobileFooter = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const isBotPage = pathname.includes('/bots/');
  const currentBot = useAppSelector(getCurrentBot);

  const [hideFooterNav, setHideFooterNav] = React.useState(false);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardDidShow', (info) => {
        if (isBotPage) {
          dispatch(scrollToEndOfChatUpdated(true));
        }
      });

      Keyboard.addListener('keyboardWillShow', (info) => {
        setHideFooterNav(true);
        dispatch(isShowingKeyboardUpdated(true));
      });

      Keyboard.addListener('keyboardWillHide', () => {
        setHideFooterNav(false);
        dispatch(isShowingKeyboardUpdated(false));
      });
    }

    return () => {
      if (Capacitor.isNativePlatform()) {
        Keyboard.removeAllListeners();
      }
    };
  }, [dispatch, isBotPage]);

  const isShowingKeyboard = useAppSelector((s) => s.ui.isShowingKeyboard);

  const pressTimer = React.useRef<NodeJS.Timeout>();

  const handlePointerDown = React.useCallback(() => {
    if (!currentBot) {
      return;
    }
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    pressTimer.current = setTimeout(async () => {
      const result = await showSystemPromptActions(
        currentBot.currentSystemPrompt
      );
      if (result === 0) {
        navigate('/edit-bot', { state: { bot: currentBot } });
        setTimeout(() => {
          dispatch(focusEditBotSystemPromptUpdated(true));
        }, 150);
      }
    }, 300);
  }, [currentBot, dispatch, navigate]);

  const handlePointerUp = () => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    clearTimeout(pressTimer.current);
  };

  const hasHistory = currentBot
    ? Object.keys(currentBot.chatHistory).length > 0
    : false;

  const maybeChatInput = isBotPage ? (
    <Paper
      sx={{
        px: 1,
        pt: 1,
        pb: isShowingKeyboard ? 1 : Capacitor.isNativePlatform() ? 3 : 1.5,
        backgroundColor: 'background.default',
      }}
    >
      {currentBot?.currentSystemPrompt && !hasHistory ? (
        <Typography
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerLeave={handlePointerUp}
          sx={{
            color: 'text.secondary',
            pb: 2,
            px: 1.5,
            userSelect: Capacitor.isNativePlatform() ? 'none' : undefined,
          }}
        >
          {currentBot?.currentSystemPrompt}
        </Typography>
      ) : null}
      <ChatInput />
    </Paper>
  ) : null;

  const hideMobileFooter = true;

  return (
    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
      {maybeChatInput}
      {hideFooterNav || hideMobileFooter ? null : (
        <Paper elevation={3}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            sx={{ py: 4 }}
          >
            <BottomNavigationAction
              onClick={() => navigate('/')}
              disableRipple
              label="Bots"
              icon={<SmartToy sx={{ mb: 0.5 }} />}
            />
            <BottomNavigationAction
              disableRipple
              onClick={() => navigate('/prompts')}
              label="Templates"
              icon={<Receipt sx={{ mb: 0.5 }} />}
            />
            <BottomNavigationAction
              disableRipple
              onClick={() => navigate('/account')}
              label="Me"
              icon={<Person sx={{ mb: 0.5 }} />}
            />
          </BottomNavigation>
        </Paper>
      )}
    </Box>
  );
};

export default MobileFooter;
