import { configureStore } from '@reduxjs/toolkit';
import appReducer from './domain/app/appSlice';
import botReducer from './domain/bot/botSlice';
import playgroundReducer from './domain/playground/playgroundSlice';
import uiReducer from './domain/ui/uiSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    bot: botReducer,
    playground: playgroundReducer,
    ui: uiReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
