import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const WizardSelect = () => {
  const navigate = useNavigate();
  const languageTutor = (
    <MenuItem
      onClick={() => {
        navigate('/language-tutor');
      }}
    >
      <Typography>Language Tutor</Typography>
    </MenuItem>
  );

  const programming = (
    <MenuItem
      onClick={() => {
        navigate('/programming');
      }}
    >
      <Typography>Programming</Typography>
    </MenuItem>
  );

  const subjects = (
    <MenuItem
      onClick={() => {
        navigate('/subjects');
      }}
    >
      <Typography>School Subjects</Typography>
    </MenuItem>
  );

  return (
    <FormControl fullWidth>
      <Select defaultValue={-1}>
        <MenuItem value={-1}>
          <em>Choose a template...</em>
        </MenuItem>
        {languageTutor}
        {programming}
        {subjects}
      </Select>
    </FormControl>
  );
};

export default WizardSelect;
