import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getStaticUrl } from '../../helpers/staticHelpers';
import { useAppDispatch } from '../../hooks';
import {
  botDeleted,
  currentBotIdUpdated,
} from '../../redux/domain/bot/botSlice';
import { Bot } from '../../types';
import BotPreview2 from '../BotPreview2';

const DeleteBotPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const bot: Bot | undefined = location.state?.bot;

  const onClose = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const onConfirmDeleteBot = useCallback(async () => {
    if (!bot) {
      return;
    }
    dispatch(botDeleted(bot.id));
    dispatch(currentBotIdUpdated(undefined));
    onClose();
    navigate('/');
    return;
  }, [bot, dispatch, navigate, onClose]);

  const [showPrompt, setShowPrompt] = useState(false);
  const hasShowPromptButton = false;
  const showPromptButton = hasShowPromptButton ? (
    <Box sx={{ pt: 2 }}>
      <Button
        onClick={() => setShowPrompt(!showPrompt)}
        sx={{ borderRadius: '2rem', width: 'fit-content' }}
        variant="outlined"
      >
        {showPrompt ? 'Hide Prompt' : 'Show Prompt'}
      </Button>
    </Box>
  ) : null;

  if (!bot) {
    return null;
  }

  return (
    <Box sx={{ px: 2 }}>
      <Stack spacing={1}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            borderRadius: '1rem',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <BotPreview2 bot={bot} />
            {showPromptButton}
            {showPrompt ? (
              <Box
                sx={{
                  p: { xs: 1.5, md: 2 },
                  backgroundColor: '#FFFFCF',
                  borderRadius: '1rem',
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: 14, md: 24 },
                    letterSpacing: 1,
                    lineHeight: 1.5,
                  }}
                >
                  {bot.currentSystemPrompt}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box sx={{ py: 1 }}>
          <Alert
            icon={<></>}
            variant="standard"
            color="error"
            sx={{ borderRadius: '0.5rem', p: 0 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ ml: 1 }} variant="h6">
                This bot will be lost forever.
              </Typography>
            </Box>
          </Alert>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            sx={{ borderRadius: '2rem' }}
            fullWidth
            onClick={onClose}
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <Button
            sx={{ borderRadius: '2rem' }}
            fullWidth
            color="error"
            variant="contained"
            onClick={onConfirmDeleteBot}
            size="large"
          >
            Delete
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              p: 2,
              height: { xs: 200, md: 400 },
              width: { xs: 200, md: 400 },
            }}
            alt="Delete bot"
            src={getStaticUrl('/icons/deleteBot.svg')}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default DeleteBotPage;
