import { Box, Link, Stack, Typography } from '@mui/material';

import { track } from '../../helpers/analyticsHelpers';
import { openUrl } from '../../mobile/browser';
import { ClickEvent } from '../../types';

export default function AboutPage() {
  return (
    <Box sx={{ p: { xs: 1, md: 2 } }}>
      <Stack spacing={2}>
        <Box>
          <Typography sx={{ fontWeight: 600 }} variant="h6">
            What is Darn Bots?
          </Typography>
          <Typography>
            Darn Bots lets you harness the power of AI with simple, customizable
            chat bots.
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 600 }} variant="h6">
            What models are supported?
          </Typography>
          <Typography variant="body2">Open AI</Typography>
          <Stack sx={{ ml: 1 }}>
            <Link
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
              onClick={async () =>
                await openUrl(
                  'https://platform.openai.com/docs/models/gpt-4o-mini'
                )
              }
              variant="body1"
            >
              gpt-4o-mini
            </Link>
          </Stack>
          <Typography variant="body2">
            Additional models and customization coming soon!
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 600 }} variant="h6">
            Who makes Darn Bots?
          </Typography>
          <Link
            sx={{ textDecoration: 'none', ml: 0.5, cursor: 'pointer' }}
            onClick={async () => {
              track(ClickEvent.DogDayAppsClicked);
              await openUrl('https://dogdayapps.com');
            }}
            variant="body1"
          >
            Dog Day Apps, LLC
          </Link>
        </Box>
      </Stack>
    </Box>
  );
}
