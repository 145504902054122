import { Capacitor } from '@capacitor/core';
import {
  Box,
  Fab,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useOnScreen } from '../../hooks';
import {
  currentBotIdUpdated,
  getCurrentBot,
} from '../../redux/domain/bot/botSlice';
import { scrollToEndOfChatUpdated } from '../../redux/domain/ui/uiSlice';
import ChatHistory from '../ChatHistory';
import ChatInput from '../ChatInput';

import { KeyboardArrowDown } from '@mui/icons-material';
import { Helmet } from 'react-helmet';

const BotPage = () => {
  const dispatch = useAppDispatch();
  const endOfChatRef = useRef<HTMLElement | null>();
  const isVisible = useOnScreen(endOfChatRef);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = Capacitor.isNativePlatform() || isSmallScreen;

  const currentBot = useAppSelector(getCurrentBot);

  const { botId = '' } = useParams();

  const scrollToEndOfChat = useAppSelector((s) => s.ui.scrollToEndOfChat);

  useEffect(() => {
    setTimeout(() => {
      if (endOfChatRef.current) {
        endOfChatRef.current.scrollIntoView({ behavior: 'auto' });
      }
    }, 50);
  }, [dispatch]);

  useEffect(() => {
    if (scrollToEndOfChat) {
      if (endOfChatRef.current) {
        endOfChatRef.current.scrollIntoView({ behavior: 'auto' });
      }
      dispatch(scrollToEndOfChatUpdated(false));
      return;
    }
  }, [dispatch, scrollToEndOfChat]);

  useEffect(() => {
    if (botId) {
      dispatch(currentBotIdUpdated(botId));
    }
  }, [botId, dispatch]);

  const hasHistory = currentBot
    ? Object.keys(currentBot.chatHistory).length > 0
    : false;

  const footer = isMobileView ? null : (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        px: 1,
        pt: 2,
        pb: 3,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      }}
      elevation={3}
    >
      {currentBot?.currentSystemPrompt && !hasHistory ? (
        <Typography
          sx={{
            color: 'text.secondary',
            pb: 2,
            px: 1.5,
            userSelect: Capacitor.isNativePlatform() ? 'none' : undefined,
          }}
        >
          {currentBot?.currentSystemPrompt}
        </Typography>
      ) : null}
      <ChatInput />
    </Paper>
  );

  return (
    <>
      <Helmet>
        <title>{currentBot ? currentBot.name : 'Darn Bots'}</title>
      </Helmet>
      <Box sx={{ p: 1, mx: 'auto' }}>
        {currentBot ? <ChatHistory bot={currentBot} /> : null}
        <Box ref={endOfChatRef} />
        {footer}
      </Box>
      {isVisible ? null : (
        <Fab
          onClick={() => {
            if (endOfChatRef.current) {
              endOfChatRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          sx={{
            position: 'fixed',
            bottom: '100px',
            right: '16px',
          }}
        >
          <KeyboardArrowDown />
        </Fab>
      )}
    </>
  );
};

export default BotPage;
