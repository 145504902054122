import {
  Camera,
  CameraPermissionType,
  CameraResultType,
  CameraSource,
} from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import { showShortToast } from './toast';

const checkPermissions = async (): Promise<boolean> => {
  let { camera, photos } = await Camera.checkPermissions();

  let permissionsToRequest: CameraPermissionType[] = [];
  if (camera === 'prompt' || camera === 'prompt-with-rationale') {
    permissionsToRequest.push('camera');
  }

  if (photos === 'prompt' || photos === 'prompt-with-rationale') {
    permissionsToRequest.push('photos');
  }

  if (permissionsToRequest.length) {
    await Camera.requestPermissions({ permissions: permissionsToRequest });
    return checkPermissions();
  }

  const hasCameraPermission = camera === 'granted' || camera === 'limited';
  const hasPhotoPermission = photos === 'granted' || photos === 'limited';

  return hasCameraPermission || hasPhotoPermission;
};

const getSource = async () => {
  const { camera, photos } = await Camera.checkPermissions();
  if (camera === 'granted' && photos === 'granted') {
    return CameraSource.Prompt;
  } else if (photos === 'granted') {
    return CameraSource.Photos;
  } else {
    return CameraSource.Camera;
  }
};

export const getPhotoFromCamera = async (): Promise<string | void> => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }
  const hasCameraPermissions = await checkPermissions();

  if (!hasCameraPermissions) {
    await showShortToast(
      'Unable to access camera or photos. Update your permissions in the app settings if you would like to access this feature.'
    );
    await NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
    return;
  }

  const image = await Camera.getPhoto({
    source: await getSource(),
    quality: 90,
    presentationStyle: 'popover',
    promptLabelHeader: 'Bot Avatar',
    promptLabelPhoto: 'Choose a photo',
    promptLabelPicture: 'Take a picture',
    allowEditing: true,
    resultType: CameraResultType.Uri,
  });

  return image.webPath;
};
