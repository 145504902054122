import { Box, Button, Container, Divider, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  currentBotIdUpdated,
  getCurrentBot,
} from '../../redux/domain/bot/botSlice';
import { Bot } from '../../types';
import BotWidget from '../BotWidget';
import PlaygroundWidget from '../PlaygroundWidget';

function sortBotsByLatestActivity(bots: Bot[]) {
  const getLatestActivity = (bot: Bot) => {
    const messages = Object.values(bot.chatHistory);
    return messages.length > 0
      ? messages[messages.length - 1].updated_ts
      : parseInt(Object.keys(bot.systemPromptHistory)[0], 10);
  };
  return bots.sort((a, b) => getLatestActivity(b) - getLatestActivity(a));
}

let lastBotCount = 0;
let lastSortedBots: Bot[] = [];

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const bots = useAppSelector((s) => Object.values(s.bot.botMap));
  const currentBot = useAppSelector(getCurrentBot);

  useEffect(() => {
    if (currentBot) {
      dispatch(currentBotIdUpdated(undefined));
    }
  }, [currentBot, dispatch]);

  const sortedBots = useMemo(() => {
    const countChanged = lastBotCount !== bots.length;
    const nameChanged = bots.some(
      (bot) => bot.name !== lastSortedBots.find((b) => b.id === bot.id)?.name
    );
    const promptChanged = bots.some(
      (bot) =>
        bot.currentSystemPrompt !==
        lastSortedBots.find((b) => b.id === bot.id)?.currentSystemPrompt
    );

    const hasChanged = countChanged || nameChanged || promptChanged;
    if (hasChanged) {
      lastBotCount = bots.length;
      lastSortedBots = sortBotsByLatestActivity(bots);
    }
    return lastSortedBots;
  }, [bots]);

  const addBotButton = (
    <Button
      sx={{ borderRadius: '2rem' }}
      onClick={() => {
        navigate('/add-bot');
      }}
      variant="outlined"
    >
      Add Bot
    </Button>
  );

  return (
    <Container>
      <PlaygroundWidget />
      <Divider sx={{ mb: 2 }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            userSelect: 'none',
            fontSize: '20px',
            fontFamily: 'Lato',
            cursor: 'pointer',
            fontWeight: 600,
          }}
        >
          Bots
        </Typography>
        {addBotButton}
      </Box>
      {sortedBots.map((bot) => (
        <BotWidget key={bot.id} bot={bot} />
      ))}
    </Container>
  );
};

export default HomePage;
