import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Box>
      <Typography variant="h6">{`We couldn't find that page.`}</Typography>
      <Link to="/">
        <Button>Take me home</Button>
      </Link>
    </Box>
  );
};

export default NotFoundPage;
