import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks';
import {
  appInfoUpdated,
  mobileBatteryInfoUpdated,
  mobileDeviceInfoUpdated,
} from '../../redux/domain/app/appSlice';

const GetMobileInfo = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const logAppInfo = async () => {
      const info = await App.getInfo();
      dispatch(appInfoUpdated(info));
    };
    const logDeviceInfo = async () => {
      const info = await Device.getInfo();
      dispatch(mobileDeviceInfoUpdated(info));
    };

    const logBatteryInfo = async () => {
      const info = await Device.getBatteryInfo();
      dispatch(mobileBatteryInfoUpdated(info));
    };

    logAppInfo();
    logDeviceInfo();
    logBatteryInfo();
  }, [dispatch]);

  return null;
};

export default GetMobileInfo;
