import { blueGrey, green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: green[900],
    },
    secondary: blueGrey,
    background: {
      default: '#FCFCFC',
      paper: '#FCFCFC',
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: green,
    secondary: blueGrey,
    background: {
      default: '#202226',
      paper: '#202226',
    },
  },
});
