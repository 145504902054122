export interface Message {
  id: string;
  created_ts: number;
  updated_ts: number;
  content: string;
  prompt: string;
  type: 'message';
}

export type ChatHistory = Record<string, Message>;
export type SystemPromptHistory = Record<number, string>;

export type Colors = {
  header: string;
  user: string;
  bot: string;
};

export type Bot = {
  id: string;
  chatHistory: ChatHistory;
  name: string;
  streamResponse: string;
  currentSystemPrompt: string;
  systemPromptHistory: SystemPromptHistory;
  created_ts: number;
  updated_ts: number;
};

export type BotMap = Record<string, Bot>;
export type BotVisMap = Record<string, boolean>;

export enum AddBotTab {
  Menu = 'Menu',
  Custom = 'Custom',
  Template = 'Template',
  Wizard = 'Wizard',
}

export enum EventType {
  BotCreated = 'BotCreated',
  PromptUpdated = 'PromptUpdated',
}

export const isEventType = (type: string): type is EventType =>
  Object.values(EventType).includes(type as EventType);

export type CommonEventInfo = {
  id: string;
  created_ts: number;
};

export interface BotCreatedEvent {
  type: EventType.BotCreated;
  payload: {
    bot: Bot;
  };
}

export interface PromptUpdatedEvent {
  type: EventType.PromptUpdated;
  payload: {
    systemPrompt: string;
  };
}

export type Event = CommonEventInfo & (BotCreatedEvent | PromptUpdatedEvent);

export enum CategoryClickEvent {
  // Learn
  LearnProgrammingClicked = 'Programming Clicked',
  LearnLanguagesClicked = 'Languages Clicked',
  LearnSubjectsClicked = 'Subjects Clicked',
  // Build
  BuildAppsClicked = 'Build Apps Clicked',
  BuildBusinessesClicked = 'Build Businesses Clicked',
  BuildAnythingClicked = 'Build Anything Clicked',
  // Write
  WriteEmailsClicked = 'Write Emails Clicked',
  WriteResumesClicked = 'Write Resumes Clicked',
  WritePerformanceReviewsClicked = 'Write Performance Reviews Clicked',
}

export enum ClickEvent {
  // Feedback
  RateDarnBotsClicked = 'Rate Darn Bots Clicked',
  BugReportClicked = 'Bug Report Clicked',
  FeatureRequestClicked = 'Feature Request Clicked',
  // Miscellaneous
  AboutDarnBotsClicked = 'About Darn Bots Clicked',
  DogDayAppsClicked = 'Dog Day Apps Clicked',
}

export enum AnalyticsEvent {
  AccountDeleted = 'Account Deleted',
  BotCreated = 'Bot Created',
  BotUpdated = 'Bot Updated',
  BotDeleted = 'Bot Deleted',
  MessageSent = 'Message Sent',
  PromptedForAppRating = 'Prompted for app rating',
  ResponseReceived = 'Response Received',

  // Playground
  PromptSubmitted = 'Prompt Submitted',
}

export enum PreferenceKey {
  LAST_RATING_PROMPT = 'LAST_RATING_PROMPT',
}

export type RatingPrompt = {
  created_ts: number;
  version: string;
};

export type CollapsibleSection = 'playground' | 'bots';
export type CollapseStates = Record<CollapsibleSection, boolean>;
export type BotCollapseStates = Record<string, boolean>;

export enum AIModel {
  'gpt-4-turbo' = 'gpt-4-turbo',
  'gpt-4o' = 'gpt-4o',
  'gpt-4o-mini' = 'gpt-4o-mini',
}

export enum PromptCategory {
  Programming = 'programming',
  Language = 'language',
  Writing = 'writing',
}

export interface Prompt {
  name: string;
  prompt: string;
  category: PromptCategory;
}
