import { Box, Container, Divider, Typography } from '@mui/material';
import * as R from 'ramda';
import { useAppSelector } from '../../hooks';
import BotMessage from '../BotMessage';
import UserMessage from '../UserMessage';

export default function PlaygroundHistoryPage() {
  const history = useAppSelector((s) => s.playground.history);

  return (
    <Box>
      {history.length ? (
        R.reverse(history).map((pe) => {
          return (
            <>
              <Divider sx={{ my: 1 }}>
                {Intl.DateTimeFormat(undefined, {
                  dateStyle: 'short',
                  timeStyle: 'short',
                }).format(new Date(pe.created_ts))}
              </Divider>
              <Container>
                <Box key={pe.id}>
                  <UserMessage content={pe.prompt} />
                  <BotMessage content={pe.response} />
                </Box>
              </Container>
            </>
          );
        })
      ) : (
        <Typography
          variant="h5"
          color="text.secondary"
          sx={{ textAlign: 'center', p: 2 }}
        >
          No playground history
        </Typography>
      )}
    </Box>
  );
}
