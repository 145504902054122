import { Preferences } from '@capacitor/preferences';
import { PreferenceKey, RatingPrompt } from '../types';

export const recordRatingPrompt = async (ratingPrompt: RatingPrompt) => {
  await Preferences.set({
    key: PreferenceKey.LAST_RATING_PROMPT,
    value: JSON.stringify(ratingPrompt),
  });
};

export const getLastRatingPrompt = async (): Promise<
  RatingPrompt | undefined
> => {
  const ret = await Preferences.get({ key: PreferenceKey.LAST_RATING_PROMPT });
  if (ret.value) {
    return JSON.parse(ret.value);
  }
};
