import { Capacitor } from '@capacitor/core';
import { KeyboardArrowLeft } from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import { IOS_RETURN_URL_AFTER_SIGNOUT } from '../mobile/constants';
import { IPHONE_SE_MODEL } from '../redux/domain/app/appConstants';
import {
  currentBotIdUpdated,
  getCurrentBot,
} from '../redux/domain/bot/botSlice';
import { Bot } from '../types';
import BotAvatar from './BotAvatar';
import BotMenu from './BotMenu';
import UserMenu from './menus/UserMenu';
import PlaygroundMenu from './PlaygroundMenu';

const getTitle = (pathname: string, currentBot?: Bot) => {
  if (currentBot && pathname.startsWith(`/bots/${currentBot.id}`)) {
    return currentBot.name;
  }

  if (pathname === '/add-bot') {
    return 'Add Bot';
  }

  if (pathname === '/edit-bot') {
    return 'Edit Bot';
  }

  if (pathname === '/copy-bot') {
    return 'Copy Bot';
  }

  if (pathname === '/delete-bot') {
    return 'Delete Bot';
  }

  if (pathname === '/programming') {
    return 'Write code in...';
  }

  if (pathname === '/language-tutor') {
    return 'Learn a language';
  }

  if (pathname === '/terms') {
    return Capacitor.isNativePlatform() ? 'Terms of Service' : 'Darn Bots';
  }

  if (pathname === '/privacy') {
    return Capacitor.isNativePlatform() ? 'Privacy Policy' : 'Darn Bots';
  }

  if (pathname === '/support') {
    return 'Support';
  }

  if (pathname === '/about') {
    return 'About';
  }

  if (pathname === '/prompts') {
    return 'Templates';
  }

  if (pathname === '/variables') {
    return 'Variables';
  }

  if (pathname === '/mobile-debug-info') {
    return 'Mobile Debug Info';
  }

  if (pathname === '/system-prompts') {
    return 'System Prompts';
  }

  if (pathname === '/app-info') {
    return 'App Info';
  }

  if (pathname === '/subjects') {
    return 'Study a subject';
  }

  if (pathname === '/travel-itinerary') {
    return 'Travel Itinerary';
  }

  if (pathname === '/templates') {
    return 'Templates';
  }

  if (pathname === '/control-panel') {
    return 'Control Panel';
  }

  if (pathname === '/playground') {
    return 'Playground';
  }

  if (pathname === '/playground-history') {
    return 'Playground History';
  }

  if (pathname.startsWith('/bot-history')) {
    return 'Bot History';
  }

  return 'Darn Bots';
};

const TitleBar = () => {
  const { pathname, key } = useLocation();
  const dispatch = useAppDispatch();
  const currentBot = useAppSelector(getCurrentBot);
  const navigate = useNavigate();
  const playgroundHistory = useAppSelector((s) => s.playground.history);
  const hasPlaygroundHistory = playgroundHistory.length > 0;

  const isBotPage = pathname.startsWith('/bots/');

  const onTitleClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (pathname === '/terms' || pathname === '/privacy') {
      navigate('/');
    }
  }, [navigate, pathname]);

  const topLevelPages = [
    '/',
    '/prompts',
    '/variables',
    IOS_RETURN_URL_AFTER_SIGNOUT,
  ];

  const noBackButtonOnlyOnWeb = Capacitor.isNativePlatform()
    ? []
    : ['/terms', '/privacy'];
  const pagesWithoutBackButton = [...topLevelPages, ...noBackButtonOnlyOnWeb];
  const hasBackButton = !pagesWithoutBackButton.some(
    (page) => pathname === page
  );

  const onBackButtonClick = useCallback(() => {
    if (isBotPage) {
      dispatch(currentBotIdUpdated(undefined));
      navigate('/');
      return;
    }
    if (key === 'default') {
      navigate('/');
      return;
    }
    navigate(-1);
  }, [dispatch, isBotPage, key, navigate]);

  const calculatedTitle = useMemo(() => {
    return getTitle(pathname, currentBot);
  }, [pathname, currentBot]);

  const title = (
    <Box
      onClick={onTitleClick}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Typography
        noWrap
        sx={{
          overflowX: 'hidden',
          fontSize: { xs: pathname.startsWith('/bots/') ? 20 : 24, md: 32 },
          letterSpacing: calculatedTitle === 'Darn Bots' ? 2 : undefined,
          fontFamily: calculatedTitle === 'Darn Bots' ? 'Orbitron' : undefined,
          fontWeight:
            calculatedTitle === 'Darn Bots' || pathname.startsWith('/bots/')
              ? 600
              : undefined,
        }}
      >
        {calculatedTitle}
      </Typography>
    </Box>
  );

  const maybeBackButton = hasBackButton ? (
    <IconButton onClick={onBackButtonClick}>
      <KeyboardArrowLeft fontSize="large" sx={{ color: 'whitesmoke' }} />
    </IconButton>
  ) : null;

  const maybeRightAction = useMemo(() => {
    const isPlaygroundHistoryPage = pathname === '/playground-history';
    if (isPlaygroundHistoryPage && hasPlaygroundHistory) {
      return <PlaygroundMenu />;
    }
    if (currentBot && pathname.startsWith(`/bots/${currentBot.id}`)) {
      return <BotMenu isIconLarge bot={currentBot} />;
    }

    if (
      pathname === '' ||
      pathname === '/' ||
      pathname === IOS_RETURN_URL_AFTER_SIGNOUT
    ) {
      return <UserMenu />;
    }
    if (pathname === '/terms' || pathname === '/privacy') {
      return (
        <Box sx={{ opacity: 0 }}>
          <UserMenu />
        </Box>
      );
    }
  }, [pathname, hasPlaygroundHistory, currentBot]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isMobileView = Capacitor.isNativePlatform() || isSmallScreen;

  const deviceInfo = useAppSelector((s) => s.app.mobile.device);

  const appBarStyles = useMemo(() => {
    const capacitorAdjustment = Capacitor.isNativePlatform()
      ? deviceInfo?.model === IPHONE_SE_MODEL
        ? '20px'
        : '40px'
      : '0px';
    return {
      pt: isMobileView
        ? `calc(env(safe-area-inset-top) + ${capacitorAdjustment})`
        : undefined,
    };
  }, [deviceInfo?.model, isMobileView]);

  return (
    <AppBar sx={appBarStyles} position="fixed">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 60,
          px: { xs: 1, sm: 2 },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {maybeBackButton}
          {currentBot && pathname.startsWith(`/bots/${currentBot.id}`) ? (
            <BotAvatar
              bot={currentBot}
              sx={{
                width: 48,
                height: 48,
              }}
            />
          ) : null}
          {title}
        </Box>
        {maybeRightAction}
      </Box>
    </AppBar>
  );
};

export default TitleBar;
