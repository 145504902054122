import { RateApp } from 'capacitor-rate-app';
import { useCallback, useEffect, useState } from 'react';
import { track } from './helpers/analyticsHelpers';
import { useAppSelector } from './hooks';
import { getLastRatingPrompt, recordRatingPrompt } from './mobile/preferences';
import { showLongToast } from './mobile/toast';
import { AnalyticsEvent } from './types';

const PromptForAppRating = () => {
  const appInfo = useAppSelector((s) => s.app.mobile.app);
  const answerCount = useAppSelector(
    (s) => s.playground.sessionPlaygroundAnswerCount
  );
  const sessionStreamCount = useAppSelector((s) => s.app.sessionStreamCount);

  const combinedCount = answerCount + sessionStreamCount;

  const canPromptForRating = combinedCount >= 2;
  const [hasPromptedForRating, setHasPromptedForRating] = useState(false);

  const maybePromptForAppRating = useCallback(async () => {
    const currentVersion = appInfo?.version;
    if (!currentVersion) {
      return;
    }

    const lastRatingPrompt = await getLastRatingPrompt();
    const lastVersionPromptedForRating = lastRatingPrompt?.version;
    if (currentVersion === lastVersionPromptedForRating) {
      return;
    }

    setTimeout(() => {
      setHasPromptedForRating(true);
      RateApp.requestReview();
      track(AnalyticsEvent.PromptedForAppRating, {
        version: currentVersion,
      });
      recordRatingPrompt({
        created_ts: Date.now(),
        version: currentVersion,
      });
    }, 2000);
  }, [appInfo]);

  useEffect(() => {
    try {
      if (!hasPromptedForRating && canPromptForRating) {
        maybePromptForAppRating();
      }
    } catch (e) {
      showLongToast('Error prompting for app rating: ' + JSON.stringify(e));
    }
  }, [hasPromptedForRating, maybePromptForAppRating, canPromptForRating]);

  return null;
};

export default PromptForAppRating;
