import { Box, Typography } from '@mui/material';

const content = `
These Terms of Service ("Terms") govern the legal agreement between Dog Day Apps, LLC ("we," "us," or "our") and the end user ("you" or "your") regarding the use of the Darn Bots application and service ("Darn Bots"). By accessing or using Darn Bots, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using Darn Bots.

1- Description of Darn Bots
Darn Bots is a sophisticated application and service that allows users to easily customize generative AI chat bots to meet their personal needs. By providing features such as prompt duplication and refinement, Darn Bots enhances personalization and empowers users to tailor AI models to their requirements. Additionally, Darn Bots facilitates real-time collaborative learning by enabling users to invite friends to engage in AI-powered conversations and share insights.

2- Account Creation
2.1 Registration
To access and utilize Darn Bots, you must create an account. During the registration process, you agree to provide accurate, complete, and up-to-date information. You are solely responsible for maintaining the confidentiality of your account credentials and fully liable for all activities conducted under your account. Promptly notify us of any unauthorized use or suspected breach of your account.

2.2 Age Restrictions
You must be at least 18 years old or of legal age in your jurisdiction to create an account and use Darn Bots. By using Darn Bots, you represent and warrant that you meet the aforementioned age requirements.
3- User Obligations
3.1 Acceptable Use
You agree to utilize Darn Bots in compliance with applicable laws, regulations, and these Terms. You shall not engage in any unlawful or unauthorized activities through Darn Bots. Specifically, you agree not to:

Upload, transmit, or distribute any content that is illegal, harmful, threatening, abusive, defamatory, obscene, or otherwise objectionable;

Interfere with or disrupt the operation of Darn Bots or any networks connected to it;

Modify, adapt, or hack Darn Bots or mislead others by falsely associating it with another website or application;

Employ any automated means or scripts to access or collect information from Darn Bots;

Impersonate any person or entity or misrepresent yourself, your affiliation, or the origin of any content;

Engage in any activity that may compromise the security or integrity of Darn Bots.

3.2 Intellectual Property Rights
Darn Bots and all associated content, including software, text, graphics, logos, and images, are the intellectual property of Dog Day Apps, LLC or its licensors. Without prior written permission from Dog Day Apps, LLC, you agree not to copy, modify, distribute, sell, or lease any part of Darn Bots or its content.



4- Privacy
We prioritize your privacy. Our Privacy Policy, which is an integral part of these Terms, explains how we collect, use, and disclose your personal information. By using Darn Bots, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.

5- Termination
We reserve the right to suspend or terminate your access to Darn Bots at any time and for any reason, including your violation of these Terms. Upon termination, all licenses and rights granted to you under these Terms will cease immediately.

6- Disclaimer of Warranty
Darn Bots is provided "as is" and "as available" without warranties of any kind, whether express or implied. We do not warrant that Darn Bots will be uninterrupted, error-free, or free of viruses or other harmful components. Your use of Darn Bots is solely at your own risk.

7- Limitation of Liability
To the maximum extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your access to or use of Darn Bots.

8- Governing Law and Jurisdiction
These Terms shall be governed by and construed in accordance with the laws of the United States of America. Any disputes arising out of or relating to these Terms or your use of Darn Bots shall be subject to the exclusive jurisdiction of the courts located within Gilbert, Arizona.


9- Modifications to the Terms
We reserve the right to modify or replace these Terms at any time at our sole discretion. If we make material changes to these Terms, we will provide notice through Darn Bots or other means. Your continued use of Darn Bots following the posting of any changes constitutes your acceptance of such changes.

10- Miscellaneous
These Terms constitute the entire agreement between you and Dog Day Apps, LLC regarding the use of Darn Bots and supersede any prior or contemporaneous understandings. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. Our failure to enforce any right or provision of these Terms shall not be deemed a waiver of such right or provision.

If you have any questions or concerns about these Terms, please contact us at support@dogdayapps.com.

By using Darn Bots, you acknowledge that you have read, understood, and agree to these Terms.

`;

export default function TermsOfServiceContent() {
  return (
    <Box>
      {content.split('\n').map((line, index) => {
        const headingPrefixes = [
          '1-',
          '2-',
          '3-',
          '4-',
          '5-',
          '6-',
          '7-',
          '8-',
          '9-',
          '10-',
        ];
        const isHeading = headingPrefixes.some((prefix) =>
          line.startsWith(prefix)
        );

        if (isHeading) {
          return (
            <Typography
              key={`heading-line-${index}`}
              sx={{ my: 1 }}
              variant="h5"
            >
              {line}
            </Typography>
          );
        }
        const subheadingPrefixes = ['1.', '2.', '3.'];
        const isSubheading = subheadingPrefixes.some((prefix) =>
          line.startsWith(prefix)
        );
        if (isSubheading) {
          return (
            <Typography
              key={`subheading-line-${index}`}
              sx={{ my: 1 }}
              variant="h6"
            >
              {line}
            </Typography>
          );
        }
        return (
          <Typography key={`line-${index}`} sx={{ my: 1 }}>
            {line}
          </Typography>
        );
      })}
    </Box>
  );
}
