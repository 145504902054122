import { BotCollapseStates, CollapseStates } from '../types';

export enum LocalStorageKey {
  botCollapseStates = 'botCollapseStates',
  collapseStates = 'collapseStates',
  mode = 'mode',
}

export const getLocalStorageValue = (key: LocalStorageKey) => {
  const value = localStorage.getItem(key);
  return value ? value : undefined;
};

export const setLocalStorageValue = (key: LocalStorageKey, value: string) => {
  return localStorage.setItem(key, value);
};

export const getLocalStorageMode = () => {
  const value = localStorage.getItem(LocalStorageKey.mode);
  if (!value) {
    return undefined;
  }
  return value as 'light' | 'dark';
};

export const setLocalStorageMode = (value: 'light' | 'dark') => {
  return localStorage.setItem(LocalStorageKey.mode, value);
};

export const getLocalStorageCollapseStates = () => {
  const defaultCollapseStats = {
    playground: false,
    bots: false,
  };
  const value = localStorage.getItem(LocalStorageKey.collapseStates);
  if (!value) {
    return defaultCollapseStats;
  }
  return JSON.parse(value) as CollapseStates;
};

export const setLocalStorageCollapseStates = (value: CollapseStates) => {
  return localStorage.setItem(
    LocalStorageKey.collapseStates,
    JSON.stringify(value)
  );
};

export const getLocalStorageBotCollapseStates = () => {
  const value = localStorage.getItem(LocalStorageKey.botCollapseStates);
  if (!value) {
    return {};
  }
  return JSON.parse(value) as BotCollapseStates;
};

export const setLocalStorageBotCollapseStates = (value: BotCollapseStates) => {
  return localStorage.setItem(
    LocalStorageKey.botCollapseStates,
    JSON.stringify(value)
  );
};
