import { Dialog } from '@capacitor/dialog';
import { Bot } from '../types';

export const confirmSignOut = async (signout: () => void) => {
  const { value } = await Dialog.confirm({
    title: 'Sign Out',
    message: `Are you sure you want to sign out?`,
    okButtonTitle: 'Yes',
    cancelButtonTitle: 'No',
  });

  if (value) {
    signout();
  }
};

export const confirmClearPlaygroundHistory = async (
  clearHistory: () => void
) => {
  const { value } = await Dialog.confirm({
    title: 'Clear Playground History',
    message: 'Are you sure you want to clear your playground history?',
    okButtonTitle: 'Yes',
    cancelButtonTitle: 'No',
  });

  if (value) {
    clearHistory();
  }

  return value;
};

export const confirmClearHistory = async (
  clearHistory: () => void,
  bot: Bot
) => {
  const messageCount = Object.keys(bot.chatHistory).length;

  const { value } = await Dialog.confirm({
    title: 'Clear History',
    message: `Clear chat history with ${bot.name}? (${messageCount} messages)`,
    okButtonTitle: 'Yes',
    cancelButtonTitle: 'No',
  });

  if (value) {
    clearHistory();
  }

  return value;
};

export const confirmDeleteBot = async (deleteBot: () => void, bot: Bot) => {
  const messageCount = Object.keys(bot.chatHistory).length;
  const { value } = await Dialog.confirm({
    title: `Remove ${bot.name}?`,
    message: `${messageCount} messages will be lost.`,
    okButtonTitle: 'Yes',
    cancelButtonTitle: 'No',
  });

  if (value) {
    deleteBot();
  }

  return value;
};
