import { Box, Container, Divider, Typography } from '@mui/material';
import * as R from 'ramda';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import BotMessage from '../BotMessage';
import UserMessage from '../UserMessage';

export default function BotHistoryPage() {
  const { botId = '' } = useParams();
  const bot = useAppSelector((s) => s.bot.botMap[botId]);

  const history = Object.values(bot.chatHistory);

  return (
    <Box>
      <Box sx={{ p: 1 }}>
        <Typography variant="h6">{bot.name}</Typography>
      </Box>
      {history.length ? (
        R.reverse(history).map((pe) => {
          return (
            <>
              <Divider sx={{ my: 1 }}>
                {Intl.DateTimeFormat(undefined, {
                  dateStyle: 'short',
                  timeStyle: 'short',
                }).format(new Date(pe.created_ts))}
              </Divider>
              <Container>
                <Box key={pe.id}>
                  <UserMessage content={pe.prompt} />
                  <BotMessage content={pe.content} />
                </Box>
              </Container>
            </>
          );
        })
      ) : (
        <Typography
          variant="h5"
          color="text.secondary"
          sx={{ textAlign: 'center', p: 2 }}
        >
          No history
        </Typography>
      )}
    </Box>
  );
}
