import { Box, Button, Container, Stack, Typography } from '@mui/material';

import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router-dom';
import { track } from '../../helpers/analyticsHelpers';
import { getStaticUrl } from '../../helpers/staticHelpers';
import { useAppSelector } from '../../hooks';
import { openUrl } from '../../mobile/browser';
import {
  BugReportFormURL,
  FeatureRequestFormURL,
} from '../../redux/domain/app/appConstants';
import { ClickEvent } from '../../types';

const AppInfoPage = () => {
  const navigate = useNavigate();
  const appInfo = useAppSelector((s) => s.app.mobile.app);

  if (!appInfo) {
    return null;
  }

  const { build, version } = appInfo;
  return (
    <Container maxWidth="sm">
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Orbitron',
            fontWeight: 600,
            textAlign: 'center',
            mt: 2,
          }}
          variant={'h4'}
        >
          Darn Bots
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
          }}
        >
          Version {version} - Build {build}
        </Typography>
        <Box
          component="img"
          sx={{
            height: { xs: 300, md: 400 },
            width: { xs: '100%', md: 500 },
            maxWidth: '90vw',
          }}
          alt={'Wave Bot'}
          src={getStaticUrl('/icons/waveBot.svg')}
        />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 1.5,
          width: '100%',
        }}
      >
        <Button
          sx={{ width: '100%', borderRadius: 8 }}
          onClick={async () => {
            navigate('/about');
            track(ClickEvent.AboutDarnBotsClicked);
          }}
          variant="outlined"
          size="large"
        >
          About Darn Bots
        </Button>
        {Capacitor.getPlatform() === 'ios' ? (
          <Button
            sx={{ width: '100%', borderRadius: 8 }}
            onClick={async () => {
              track(ClickEvent.RateDarnBotsClicked);
              await openUrl(
                'https://apps.apple.com/app/id6451425567?action=write-review'
              );
            }}
            variant="outlined"
            size="large"
          >
            Rate Darn Bots
          </Button>
        ) : null}
        <Button
          sx={{ width: '100%', borderRadius: 8 }}
          onClick={async () => {
            track(ClickEvent.BugReportClicked);
            await openUrl(BugReportFormURL);
          }}
          variant="outlined"
          size="large"
        >
          Bug Report
        </Button>
        <Button
          sx={{ width: '100%', borderRadius: 8 }}
          onClick={async () => {
            track(ClickEvent.FeatureRequestClicked);
            await openUrl(FeatureRequestFormURL);
          }}
          variant="outlined"
          size="large"
        >
          Feature Request
        </Button>
        <Button
          sx={{ width: '100%', borderRadius: 8 }}
          onClick={() => {
            navigate('/terms');
          }}
          variant="outlined"
          size="large"
        >
          Terms of Service
        </Button>
        <Button
          sx={{ width: '100%', borderRadius: 8 }}
          onClick={() => {
            navigate('/privacy');
          }}
          variant="outlined"
          size="large"
        >
          Privacy Policy
        </Button>
      </Box>
    </Container>
  );
};

export default AppInfoPage;
