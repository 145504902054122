import { SendSharp } from '@mui/icons-material';
import { Box, IconButton, TextareaAutosize, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { track } from '../helpers/analyticsHelpers';
import { useAppDispatch, useAppSelector } from '../hooks';
import { streamFinished } from '../redux/domain/app/appSlice';
import {
  getCurrentBot,
  streamResponseUpdated,
  upsertIntoChatHistory,
} from '../redux/domain/bot/botSlice';
import { copyToPromptTextUpdated } from '../redux/domain/ui/uiSlice';
import { AIModel, AnalyticsEvent } from '../types';

let streamAnswer = '';

const getStreamUrl = (prompt: string, model: AIModel) => {
  const searchParams = new URLSearchParams();
  searchParams.set('prompt', prompt);
  searchParams.set('model', model);

  const searchQuery = searchParams.toString();
  return process.env.REACT_APP_CHAT_COMPLETION_FN + `?${searchQuery}`;
};

export default function ChatInput() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const currentBot = useAppSelector(getCurrentBot);
  const copyToPromptText = useAppSelector((s) => s.ui.copyToPromptText);

  const [prompt, setPrompt] = useState('');
  const model = useAppSelector((s) => s.playground.model);

  useEffect(() => {
    if (copyToPromptText !== undefined) {
      setPrompt(copyToPromptText);
      dispatch(copyToPromptTextUpdated(undefined));
    }
  }, [copyToPromptText, dispatch]);

  const startStream = useCallback(
    async (userPrompt: string) => {
      if (!currentBot) {
        console.error('No current bot found for streaming answer');
        return;
      }
      const { id: botId, currentSystemPrompt: systemPrompt } = currentBot;
      if (!botId) {
        throw new Error('No bot id!');
      }
      streamAnswer = '';
      setPrompt('');
      if (!process.env.REACT_APP_CHAT_COMPLETION_FN) {
        throw new Error('No REACT_APP_CHAT_COMPLETION_FN!');
      }

      const baseMessage = {
        created_ts: Date.now(),
        updated_ts: Date.now(),
        id: Date.now().toString(),
        content: '',
        prompt: `${currentBot.currentSystemPrompt} ${userPrompt}`,
        type: 'message' as const,
      };

      dispatch(upsertIntoChatHistory({ botId, message: baseMessage }));

      await track(AnalyticsEvent.MessageSent, {
        systemPrompt,
        userPrompt,
      });

      const response = await fetch(
        getStreamUrl(`${systemPrompt} ${userPrompt}`, model)
      );
      if (!response.body) {
        return;
      }
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          // console.log("Stream finished! Final answer = ", streamAnswer);
          dispatch(
            upsertIntoChatHistory({
              botId,
              message: {
                ...baseMessage,
                content: streamAnswer,
              },
            })
          );
          dispatch(streamFinished());
          await track(AnalyticsEvent.ResponseReceived, {
            prompt: `${systemPrompt} ${userPrompt}`,
            answer: streamAnswer,
          });

          dispatch(streamResponseUpdated({ botId, streamResponse: '' }));
          streamAnswer = '';
          return;
        }
        // console.log(value);
        streamAnswer += value;
        dispatch(
          upsertIntoChatHistory({
            botId,
            message: {
              ...baseMessage,
              content: streamAnswer,
            },
          })
        );
        dispatch(
          streamResponseUpdated({ botId, streamResponse: streamAnswer ?? '' })
        );
      }
    },
    [currentBot, dispatch, model]
  );

  const [showBorderStyles, setShowBorderStyles] = useState(false);
  const maybeBorderStyles = showBorderStyles
    ? {
        border: '1.75px solid',
        borderColor: theme.palette.primary.main,
      }
    : {};

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.25 }}>
      <TextareaAutosize
        onFocus={() => {
          setShowBorderStyles(true);
        }}
        onBlur={() => {
          setShowBorderStyles(false);
        }}
        placeholder="Message"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            startStream(prompt);
          }
        }}
        onChange={(e) => {
          setPrompt(e.target.value);
        }}
        value={prompt}
        style={{
          resize: 'none',
          width: '100%',
          borderRadius: '1.25rem',
          padding: '0.75rem',
          fontSize: '1rem',
          outline: 'none',
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          backgroundColor: theme.palette.background.paper,
          ...maybeBorderStyles,
        }}
      />
      <IconButton disabled={!prompt} onClick={() => startStream(prompt)}>
        <SendSharp fontSize="medium" color={prompt ? 'primary' : 'disabled'} />
      </IconButton>
    </Box>
  );
}
