import { Box, Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  botCreated,
  currentBotIdUpdated,
} from '../../redux/domain/bot/botSlice';

export default function Templates() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const prompts = useAppSelector((s) => s.app.prompts);

  return (
    <Box>
      <Stack divider={<Divider sx={{ my: 1 }} />}>
        {prompts.map((prompt) => {
          return (
            <Stack
              onClick={() => {
                const newId = uuid();
                dispatch(
                  botCreated({
                    chatHistory: {},
                    id: newId,
                    name: prompt.name,
                    currentSystemPrompt: prompt.prompt,
                    streamResponse: '',
                    systemPromptHistory: { [Date.now()]: prompt.prompt },
                    created_ts: Date.now(),
                    updated_ts: Date.now(),
                  })
                );
                dispatch(currentBotIdUpdated(newId));
                navigate(`/bots/${newId}`);
              }}
              sx={{
                p: 1,
                cursor: 'pointer',
              }}
              key={prompt.name}
            >
              <Typography fontWeight={600} variant="h6">
                {prompt.name}
              </Typography>
              <Typography>{prompt.prompt}</Typography>
            </Stack>
          );
        })}
      </Stack>
      <Divider sx={{ my: 1 }} />
    </Box>
  );
}
