import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  botCreated,
  currentBotIdUpdated,
} from '../../redux/domain/bot/botSlice';

const LanguageTutorPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const languages = useAppSelector((s) => Object.values(s.app.lists.languages));

  const onClose = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const [fromLanguage, setFromLanguage] = useState<string>('English');
  const [toLanguage, setToLanguage] = useState<string>();

  const isValid = fromLanguage && toLanguage;

  const onSave = useCallback(() => {
    const name = `${toLanguage} Tutor`;
    const systemPrompt = `You are a language tutor. I will write a message in ${fromLanguage}, please respond with the translation, transliteration, and a word-by-word breakdown of your response in ${toLanguage}. My message is: `;
    const newId = uuid();
    dispatch(
      botCreated({
        chatHistory: {},
        id: newId,
        name,
        currentSystemPrompt: systemPrompt,
        streamResponse: '',
        systemPromptHistory: { [Date.now()]: systemPrompt },
        created_ts: Date.now(),
        updated_ts: Date.now(),
      })
    );
    dispatch(currentBotIdUpdated(newId));
    navigate(`/bots/${newId}`);
  }, [toLanguage, fromLanguage, dispatch, navigate]);

  const fromLanguageSelect = (
    <Stack>
      <Typography sx={{ px: 1, fontWeight: 600 }}>I know</Typography>
      <FormControl fullWidth>
        <Select
          MenuProps={{
            sx: {
              marginTop: 2,
              height: 400,
            },
          }}
          id="language-select"
          onChange={(e: SelectChangeEvent) => {
            setFromLanguage(e.target.value);
          }}
          defaultValue="English"
        >
          <MenuItem
            sx={{ color: 'text.secondary' }}
            key="none-option"
            value="none"
          >
            Choose a language...
          </MenuItem>
          {languages.map((language) => {
            return (
              <MenuItem key={language} value={language}>
                {language}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );

  const toLanguageSelect = (
    <Stack>
      <Typography sx={{ px: 1, fontWeight: 600 }}>I want to learn</Typography>
      <FormControl fullWidth>
        <Select
          MenuProps={{
            sx: {
              marginTop: 2,
              height: 400,
            },
          }}
          id="language-select"
          onChange={(e: SelectChangeEvent) => {
            setToLanguage(e.target.value);
          }}
          defaultValue="none"
        >
          <MenuItem
            sx={{ color: 'text.secondary' }}
            key="none-option"
            value="none"
          >
            Choose a language...
          </MenuItem>
          {languages.map((language) => {
            if (language === fromLanguage) {
              return null;
            }
            return (
              <MenuItem key={language} value={language}>
                {language}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );

  return (
    <Box sx={{ p: 1 }}>
      <Stack spacing={2}>
        {fromLanguageSelect}
        {toLanguageSelect}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pt: 2 }}>
          <Button
            sx={{ borderRadius: '2rem' }}
            fullWidth
            onClick={onClose}
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <Button
            fullWidth
            sx={{ borderRadius: '2rem' }}
            variant="contained"
            disabled={!isValid}
            onClick={onSave}
            size="large"
          >
            Add Bot
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default LanguageTutorPage;
