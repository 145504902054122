import { Box, Stack } from '@mui/material';

import { useMemo } from 'react';
import { Bot } from '../types';
import BotMessage from './BotMessage';
import UserMessage from './UserMessage';

interface ConversationProps {
  bot: Bot;
}

export default function Conversation({ bot }: ConversationProps) {
  const { chatHistory } = bot;
  const maybeChatHistoryElement = useMemo(() => {
    const messages = Object.values(chatHistory);
    const hasMessages = messages.length > 0;
    if (!hasMessages) {
      return null;
    }

    return (
      <>
        {messages.map((m) => {
          const { content, prompt } = m;
          return (
            <Stack key={m.id}>
              <UserMessage content={prompt} />
              {content ? <BotMessage content={content} /> : null}
            </Stack>
          );
        })}
      </>
    );
  }, [chatHistory]);

  return <Box sx={{ pb: '25px' }}>{maybeChatHistoryElement}</Box>;
}
