import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { track } from '../../../helpers/analyticsHelpers';
import {
  AnalyticsEvent,
  Bot,
  BotMap,
  BotVisMap,
  Message,
} from '../../../types';
import { RootState } from '../../store';

export interface BotState {
  botVisMap: BotVisMap;
  botMap: BotMap;
  currentBotId?: string;
}

const finalInitialBotVisMap = () => {
  const final = localStorage.getItem('botVisMap')
    ? (JSON.parse(localStorage.getItem('botVisMap') || '{}') as Record<
        string,
        boolean
      >)
    : {};
  return final;
};

const finalInitialBotMap = () => {
  const botMap = localStorage.getItem('botMap')
    ? (JSON.parse(localStorage.getItem('botMap') || '{}') as BotMap)
    : {};
  return botMap;
};

const initialState: BotState = {
  botVisMap: finalInitialBotVisMap(),
  botMap: finalInitialBotMap(),
  currentBotId: localStorage.getItem('currentBotId')
    ? localStorage.getItem('currentBotId') ?? ''
    : undefined,
};

export const botSlice = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    promptVisibilityUpdated: (
      state,
      action: PayloadAction<{ id: string; visible: boolean }>
    ) => {
      state.botVisMap = {
        ...state.botVisMap,
        [action.payload.id]: action.payload.visible,
      };
      localStorage.setItem('botVisMap', JSON.stringify(state.botVisMap));
    },
    botCreated: (state, action: PayloadAction<Bot>) => {
      state.botMap = {
        ...state.botMap,
        [action.payload.id]: action.payload,
      };
      localStorage.setItem('botMap', JSON.stringify(state.botMap));
      track(AnalyticsEvent.BotCreated, {
        bot: action.payload,
      });
      state.botVisMap = {
        ...state.botVisMap,
        [action.payload.id]: true,
      };
      localStorage.setItem('botVisMap', JSON.stringify(state.botVisMap));
    },
    botDeleted: (state, action: PayloadAction<string>) => {
      const bot = state.botMap[action.payload];
      delete state.botMap[action.payload];
      track(AnalyticsEvent.BotDeleted, {
        bot: {
          id: bot.id,
          name: bot.name,
          systemPrompt: bot.currentSystemPrompt,
          messageCount: Object.values(bot.chatHistory).length,
        },
      });
      localStorage.setItem('botMap', JSON.stringify(state.botMap));
    },
    clearAllBots: (state) => {
      state.botMap = {};
      localStorage.setItem('botMap', JSON.stringify(state.botMap));
    },
    currentBotIdUpdated: (state, action: PayloadAction<string | undefined>) => {
      state.currentBotId = action.payload;
      if (action.payload) {
        localStorage.setItem('currentBotId', action.payload);
      } else {
        localStorage.removeItem('currentBotId');
      }
    },
    streamResponseUpdated: (
      state,
      action: PayloadAction<{ botId: string; streamResponse: string }>
    ) => {
      const { botId, streamResponse } = action.payload;
      state.botMap[botId].streamResponse = streamResponse;
    },
    upsertIntoChatHistory: (
      state,
      action: PayloadAction<{ botId: string; message: Message }>
    ) => {
      const { botId, message } = action.payload;
      state.botMap[botId].chatHistory[message.id] = message;
      state.botMap[botId].streamResponse = '';
      localStorage.setItem('botMap', JSON.stringify(state.botMap));
    },
    clearChatHistory: (state, action: PayloadAction<string>) => {
      state.botMap[action.payload].chatHistory = {};
      localStorage.setItem('botMap', JSON.stringify(state.botMap));
    },
    settingsUpdated: (
      state,
      action: PayloadAction<{
        botId: string;
        name: string;
        systemPrompt: string;
      }>
    ) => {
      const { botId, name, systemPrompt } = action.payload;
      const bot = state.botMap[action.payload.botId];
      state.botMap[botId] = {
        ...bot,
        name,
        currentSystemPrompt: systemPrompt,
      };
      track(AnalyticsEvent.BotUpdated, {
        bot: {
          id: botId,
          name,
          systemPrompt,
          messageCount: Object.values(bot.chatHistory).length,
        },
      });
      localStorage.setItem('botMap', JSON.stringify(state.botMap));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  botCreated,
  botDeleted,
  currentBotIdUpdated,
  streamResponseUpdated,
  upsertIntoChatHistory,
  clearChatHistory,
  settingsUpdated,
  promptVisibilityUpdated,
  clearAllBots,
} = botSlice.actions;

const getCurrentBotId = (state: RootState) => state.bot.currentBotId;
const getBotMap = (state: RootState) => state.bot.botMap;
export const getBotVisMap = (state: RootState) => state.bot.botVisMap;

export const getCurrentBot = createSelector(
  getBotMap,
  getCurrentBotId,
  (botMap, botId) => {
    const result = botId ? botMap[botId] : undefined;
    return result;
  }
);

export default botSlice.reducer;
