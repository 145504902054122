import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MouseEvent, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  LocalStorageKey,
  setLocalStorageValue,
} from '../helpers/localStorageHelpers';
import { useAppDispatch, useAppSelector } from '../hooks';
import { confirmClearPlaygroundHistory } from '../mobile/dialog';
import { clearHistory } from '../redux/domain/playground/playgroundSlice';
import { modeUpdated } from '../redux/domain/ui/uiSlice';

export default function PlaygroundMenu() {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const history = useAppSelector((s) => s.playground.history);
  const hasHistory = history.length > 0;
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleTheme = useCallback(() => {
    const newMode = theme.palette.mode === 'dark' ? 'light' : 'dark';
    dispatch(modeUpdated(newMode));
    setLocalStorageValue(LocalStorageKey.mode, newMode);
    handleClose();
  }, [dispatch, theme.palette.mode]);

  const onClearHistory = useCallback(async () => {
    await confirmClearPlaygroundHistory(() => dispatch(clearHistory()));
    handleClose();
  }, [dispatch]);

  return (
    <Box>
      <IconButton
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          handleClick(e);
        }}
      >
        <MoreVert fontSize="large" sx={{ color: 'whitesmoke' }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={toggleTheme}>
          {isDark ? 'Light Mode' : 'Dark Mode'}
        </MenuItem>
        {hasHistory && pathname === '/playground-history' ? (
          <MenuItem onClick={onClearHistory}>Clear History</MenuItem>
        ) : null}
      </Menu>
    </Box>
  );
}
