import { MoreVert } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MouseEvent, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import { confirmClearHistory } from '../mobile/dialog';
import {
  clearChatHistory,
  promptVisibilityUpdated,
} from '../redux/domain/bot/botSlice';
import { botExpanded, deleteBotIdUpdated } from '../redux/domain/ui/uiSlice';
import { Bot } from '../types';
import BotAvatar from './BotAvatar';

interface BotMenuProps {
  bot: Bot;
  iconColor?: string;
  isIconLarge?: boolean;
}
export default function BotMenu({
  bot,
  iconColor = 'whitesmoke',
  isIconLarge = false,
}: BotMenuProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const hasHistory = Object.keys(bot.chatHistory).length > 0;
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClearHistory = useCallback(async () => {
    const clearHistory = () => dispatch(clearChatHistory(bot.id));
    await confirmClearHistory(clearHistory, bot);
    handleClose();
  }, [dispatch, bot]);

  const onViewHistory = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate(`/bot-history/${bot.id}`);
      handleClose();
    },
    [bot, navigate]
  );

  const onCopyBot = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate('/copy-bot', { state: { bot } });
      handleClose();
    },
    [bot, navigate]
  );

  const onEditBot = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      navigate('/edit-bot', { state: { bot } });
      handleClose();
    },
    [bot, navigate]
  );

  const isCollapsed = useAppSelector(
    (s) => s.ui.botCollapseStates[bot.id] === true
  );

  const onDeleteBot = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      if (pathname === '/') {
        if (isCollapsed) {
          dispatch(botExpanded(bot.id));
        }
        dispatch(deleteBotIdUpdated(bot.id));
      } else {
        navigate('/delete-bot', { state: { bot } });
      }
      handleClose();
    },
    [bot, dispatch, isCollapsed, navigate, pathname]
  );

  const isHidingPrompt = useAppSelector(
    (s) => s.bot.botVisMap[bot.id] === false
  );

  const onHidePrompt = useCallback(() => {
    dispatch(promptVisibilityUpdated({ id: bot.id, visible: false }));
    handleClose();
  }, [bot.id, dispatch]);

  const onShowPrompt = useCallback(() => {
    dispatch(promptVisibilityUpdated({ id: bot.id, visible: true }));
    handleClose();
  }, [bot.id, dispatch]);

  return (
    <Box>
      <IconButton
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          handleClick(e);
        }}
      >
        <MoreVert
          fontSize={isIconLarge ? 'large' : undefined}
          sx={{ color: iconColor }}
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box
          onClick={() => {
            navigate('/bots/' + bot.id);
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: 1,
            pb: 1,
            cursor: 'pointer',
          }}
        >
          <BotAvatar bot={bot} sx={{ width: 50, height: 50 }} />
          <Typography variant="h6">{bot.name}</Typography>
        </Box>
        <Divider />
        <MenuItem
          onClick={() => {
            navigate('/bots/' + bot.id);
          }}
        >
          View Bot
        </MenuItem>
        {pathname === '/' && !isCollapsed ? (
          <MenuItem onClick={isHidingPrompt ? onShowPrompt : onHidePrompt}>
            {isHidingPrompt ? 'Show Prompt' : 'Hide Prompt'}
          </MenuItem>
        ) : null}
        <MenuItem onClick={onViewHistory}>View History</MenuItem>
        <MenuItem onClick={onEditBot}>Edit Bot</MenuItem>
        <MenuItem onClick={onCopyBot}>Copy Bot</MenuItem>
        {hasHistory ? (
          <MenuItem onClick={onClearHistory}>Clear History</MenuItem>
        ) : null}
        <MenuItem onClick={onDeleteBot}>Delete Bot</MenuItem>
      </Menu>
    </Box>
  );
}
